import React, {Component} from 'react';

import "../App3_css/S4_Li.css"
import "../App3_css/S4_Chi.css"


export default class S4_Chi extends Component {
    render() {
        return (
            <div id="S4_Chi" class="S4_box" style={{display:'none'}}>
                <div class="S4_Li_Curtain S4_Chi_Curtain">

                    <div >
                        <h3>Solid state<br/> battery r&d</h3>
                        <p>
                            A breakthrough in energy density<br/>
                            has become the "heart" of power batteries
                        </p>
                    </div>
                </div>
                <div class=" S4_Chi_content">
                    <div class="S4_Li_box">
                        <div className="S1_top S4_Li_top">
                            <div class="S4_Chi_top_title wow fadeInUp bg-green" data-wow-delay="1s" data-wow-delay="1s" >
                                <h3 style={{"text-transform":"uppercase"}}>Big turn of power battery</h3>
                                <p>

                                    Committed to independent research and development of solid-state battery technology,<br/>
                                    the storage of electricity is greater, which can make new energy vehicles last longer and safer.

                                </p>
                            </div>
                        </div>
                        <div class="S4_Li_bot S4_Chi_bot">
                            <ul>
                                <li class="S4_Li_top_title wow slideInUp bg-green" data-wow-delay="1s" data-wow-duration="1s" >
                                    <h3>Light-high density</h3>
                                    <p>
                                        After using the all-solid electrolyte, the applicable material system of the lithium ion battery will also change, and the metal lithium is directly used as the negative electrode, which can obviously reduce the amount of the negative electrode material, so that the energy density of the entire battery is significantly improved.
                                    </p>
                                </li>
                                <li class="S4_Li_top_title wow slideInUp bg-green" data-wow-delay="1.2s" data-wow-duration="1s">
                                    <h3>Thin - small</h3>
                                    <p>
                                        Substituted by a solid electrolyte (mainly two systems of organic and inorganic ceramic materials), the distance between the positive and negative electrodes (conventionally filled by a diaphragm electrolyte, now filled by a solid electrolyte) can be shortened to even a few to a few microns So that the thickness of the battery can be greatly reduced
                                    </p>
                                </li>
                                <li class="S4_Li_top_title wow slideInUp bg-green" data-wow-delay="1.4s" data-wow-duration="1s">
                                    <h3>Flexible prospects</h3>
                                    <p>
                                        Even brittle ceramic materials are often bendable after thicknesses as thin as a millimeter or less, and the material becomes flexible. Correspondingly, the degree of flexibility of the all-solid-state battery will be significantly improved after being light and thin.
                                    </p>
                                </li>
                                <li class="S4_Li_top_title wow slideInUp bg-green" data-wow-delay="1.6s" data-wow-duration="1s">
                                    <h3>safer</h3>
                                    <p>
                                        Traditional lithium batteries may have the following dangers: (1) Lithium dendrites may occur when working at high currents, causing short-circuit damage by piercing the diaphragm. (2) Side reactions, oxidative decomposition, gas generation, and combustion occur at high temperatures. Intensified. With all solid-state battery technology, the above two problems can be directly solved
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}