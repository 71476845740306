import React, {Component} from 'react';

import "../../Css_List/App7_Contact_css/Contact_Recruit.css"

import zhankai from '../../images/img7_Contact/zhankai-1.png'
import dizhi from '../../images/img7_Contact/dizhi.png'
import dianhua from '../../images/img7_Contact/dianhua.png'
import youxiang from '../../images/img7_Contact/youxiang.png'

import axios from  'axios'

import Domain_Name from '../Domain_Name'
import Request_Head from "../Request_Head";

export default class Contact_Recruit extends Component {

    constructor(props){
        super(props);
        var Request_Token = Request_Head;
        this.state={
            Token:Request_Token,/*请求头*/
            Recruitment:[],
            value:'',
            isLoaded:false
        }
    }
    componentWillMount (){
        var Domain_head = Domain_Name;
        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        axios.post(Domain_head+'/api/recruit/data', {
            job_name: this.state.value,
            lang_type:'2',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Recruitment:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    Change(e){
        this.setState({
            value:e.target.value
        })
    }
    Click(){
        var Domain_head = Domain_Name;
        const _this=this;

        axios.post(Domain_head+"/api/recruit/data", {
            job_name: this.state.value,
            lang_type:'2'
        })
            .then(function (response) {
                _this.setState({
                    Recruitment:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div  className="Contact_Recruit  display_block display">
                <div className="Contact_Recruit_box">
                    <div className="Recruit_box_top">

                        <h1>Haidi talent recruitment</h1>

                        <div className="Recruit_box_top_form">
                            <a onClick={this.Click.bind(this)} href="javascript:;"></a>
                            <input type="text" onChange={this.Change.bind(this)} placeholder="Please enter position keywords"/>
                        </div>
                    </div>
                    <div className="Contact_Recruit_content">

                        <ul className="Recruit_content_div_list">
                            <li className="Recruit_content_title">
                                <ul >
                                    <li><h3>Job title</h3></li>
                                    <li><h3>work place</h3></li>
                                    <li><h3>Number of recruits</h3></li>
                                    <li><h3>Release time</h3></li>
                                </ul>
                            </li>
                            {this.state.Recruitment.map((data,i)=>{
                                var work_duty = data.work_duty;
                                var qualification = data.qualification;
                                var apply_phone = data.apply_phone;

                                return(
                                    <li className="content_div_li" key={i}>
                                        <ul className="content_div_li_ul">
                                            <li><p className="job_name">{data.job_name}</p></li>
                                            <li><p>{data.work_place}</p></li>
                                            <li><p>{data.recruit_num}</p></li>
                                            <li>
                                                <p>{data.update_time.split(" ")[0]}
                                                    <a className="click_rotate" href="javascript:;"><img src={zhankai} alt=""/></a>
                                                </p>
                                            </li>
                                        </ul>
                                        <div className="Recruit_content_div">
                                            <div className="Recruit_content_center">
                                                Job responsibilities<br/>
                                                <div dangerouslySetInnerHTML={{ __html: work_duty}} />
                                                <p></p>
                                                Qualifications<br/>
                                                <div dangerouslySetInnerHTML={{ __html: qualification}} />
                                                <p></p>
                                                job type<br/>
                                                full time<br/>
                                            </div>
                                            <div className="Recruit_content_bot">
                                                <ul>
                                                    <li>
                                                        <img src={dizhi} alt=""/>
                                                        <div>
                                                            <p>Application address<br/>
                                                                {data.apply_address}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <img src={dianhua} alt=""/>
                                                        <div>
                                                            <p>Application contact number<br/>
                                                                <span dangerouslySetInnerHTML={{ __html: apply_phone}} />
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <img src={youxiang} alt=""/>
                                                        <div>
                                                            <p>Resume delivery mailbox<br/>
                                                                {data.resume_email}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                            }


                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}