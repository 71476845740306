import React, {Component} from 'react';

import "../App3_css/Section_2.css"
import AnQuan from '../images/img3/AnQuan-red.png'

export default class Section_2 extends Component {
    render() {
        return (
            <div id="Section_2">

                <div class="S2_Y">

                    <div className=" S1_top S2_top " >
                        <h3 style={{"text-transform":"uppercase"}}>LITHIUM BATTERY CELL AT THE HEAR OF HIGH PERFORMANCE</h3>
                        <p>
                            HAIDI Energy is a true leader in cell technology. Our lithium-ion cell technology combines lithium-ion chemistry, low-impedance cell design and world class manufacturing systems to give customers an unprecedented cost/performance advantage over any other technology.
                        </p>
                    </div>
                    <div class="S2_list" >
                        <ul>
                            <li >
                                <div class="S2_list_Ld" >
                                    <div class="S2_li_img S2_T1" data-original={AnQuan}></div>
                                    <h3>safety</h3>
                                  {/*  <p>
                                        Starting graphene battery equipped with intelligent BMS which ensures the voltageand current are controlled intelligently,also designing with waterproof and fireproof function.
                                    </p>*/}
                                </div>
                            </li>
                            <li>
                                <div className="S2_list_Ld">
                                    <div className="S2_li_img S2_T2"></div>
                                    <h3>cycle life</h3>
                                   {/* <p>
                                        Ultra-long life- 100,000 times, One time investment without worrying about replacing the battery for lifetime10 years lifetime,5 years warranty.
                                    </p>*/}
                                </div>
                            </li>
                            <li>
                                <div className="S2_list_Ld">
                                    <div className="S2_li_img S2_T3"></div>
                                    <h3>quality</h3>
                                    {/*<p>
                                        Our company has 15 years experience in developing and producting lithium ironphosphate batteries.
                                    </p>*/}
                                </div>
                            </li>
                            <li>
                                <div className="S2_list_Ld">
                                    <div className="S2_li_img S2_T4"></div>
                                    <h3>low consuption</h3>
                                   {/* <p>
                                        Adopting the cutting-edge technology of data collection and processing systemreduce the energy consuption by 20%
                                    </p>*/}
                                </div>
                            </li>
                            <li>
                                <div className="S2_list_Ld">
                                    <div className="S2_li_img S2_T5"></div>
                                    <h3>ultra-light</h3>
                                    {/*<p>
                                        lightweight,reliable and environmentally-friendly solutions. They can take place as a direct drop-in replacement of low-performance lead Acid batteries
                                    </p>*/}
                                </div>
                            </li>
                            <li>
                                <div className="S2_list_Ld">
                                    <div className="S2_li_img S2_T6"></div>
                                    <h3>hight energy </h3>
                                    {/*<p>
                                        Our light weight batteries ofter a very high level of power for easy start of internalcombustion engine.
                                    </p>*/}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}