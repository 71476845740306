import React from 'react';

import '../Css_List/App5_Global_css/App5.css'

import Banner from "../Js_List/App7_Contact_js/App7_Contact_banner"
import Contact_select from "../Js_List/App7_Contact_js/Contact_select"
import Footer from "../App1_js/footer";

class App7_Contact extends React.Component{
    render() {
        return (
            <div className="App6">
                <Banner/>
                <Contact_select/>
                <Footer/>
            </div>
        );
    }
}

export default App7_Contact;