import React, {Component} from 'react';

import "../../Css_List/App5_Global_css/Banner.css"
import banner1 from '../../images/img5_Global/banner.png'

import Logo from "../../App1_js/logo";

export default class Banner extends Component {
    render() {
        return (
            <div id="" class="lazy_bg Global_banner" data-original={banner1} style={{"background-attachment": "fixed"}}>
                <Logo/>
                <div class="Banner_title">

                    <h3 data-scroll-reveal="enter left over 0.5s and move 400px after 0.2s" style={{"text-transform":"uppercase"}}>
                        Service philosophy
                    </h3>
                    <div data-scroll-reveal="enter left over 0.5s and move 400px after 0.2s">
                        <p>
                            Integrity、Innovation、Service、Win-win cooperation</p>
                        <p style={{margin:"0"}}>
                            Haidi Energy follows the principle of sustainable management<br/>
                            Create sustainable future values and serve the society with professional technology.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}