import React, {Component} from 'react';

import "../App3_css/Section_4.css"
import "../App4_Css/Cp_Select.css"

import DianXin from '../App4_Js/DianXin'
import DianDongGJ from '../App4_Js/DianDongGJ'
import MoZu from '../App4_Js/MoZu'
import QianS from '../App4_Js/QianS'
import DianDongC from '../App4_Js/DianDongC'
import ChuNengXT from '../App4_Js/ChuNengXT'


export default class Cp_Select extends Component {

    render() {
        return (
            <div id="Section_4">
                <div className="S4_router Cp_Select">
                    <ul>
                        <li class="red"><a >BATTERY CELL</a></li>
                        <li  ><a>Module</a></li>
                        <li  >
                            <a>
                                POWER TOOL<br/>
                                BATTERY
                            </a>
                        </li>
                        <li  >
                            <a>
                                LEAD ACID<br/>
                                REPLACEMENT BATTERY
                            </a>
                        </li>
                        <li  >
                            <a>
                                ELECTRIC VEHICLE<br/>
                                BATTERY
                            </a>
                        </li>
                        <li  >
                            <a>
                                ENERGY STORAGE<br/>
                                SYSTEM
                            </a>
                        </li>
                    </ul>
                </div>

                <DianXin />
                <MoZu/>
                <DianDongGJ/>
                <QianS/>
                <DianDongC/>
                <ChuNengXT/>
            </div>
        )
    }
}