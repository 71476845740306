import React, {Component} from 'react';

import "../App2_css/Modular3.css"

import {Container,Row,Col} from 'react-bootstrap'

import img1 from '../images/img2/1.png'
import img2 from '../images/img2/2.png'
import img3 from '../images/img2/3.png'
import bg4 from '../images/img2/bg4.jpg'

export default class Modular3 extends Component {
    render() {
        return (
            <div id="Mod3">
                <div class="A2_box">
                    <div className="A2_top">
                        <div class="Mod3_content">
                            <h3>Make Earth Green Again.</h3>
                            <div className="Mod3_div">
                                <Container style={{"max-width": "100%!important;"}}>
                                    <Row>
                                        <Col lg={4} md={4} sm={4}>
                                            <div  class="Mod3_col">
                                                <div>
                                                    <img data-original={img1} alt=""/>
                                                </div>
                                                <p>
                                                    Integrity, Innovation, Service, and
                                                    Win-Win Cooperation.
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={4} sm={4}>
                                            <div  class="Mod3_col">
                                                <div>
                                                    <img data-original={img2} alt=""/>
                                                </div>
                                                <p>
                                                    Harmonious, Innovative, and Market,
                                                    Expanding Unity, Hard Working to
                                                    creat a better future.
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={4} sm={4}>
                                            <div  class="Mod3_col">
                                                <div>
                                                    <img  data-original={img3} alt=""/>
                                                </div>
                                                <p>
                                                    Quality earns customer, Repurtation
                                                    forges value.
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                    <div className="A2_bot Mod3_bot lazy_bg" data-original={bg4}>
                    </div>
                </div>
            </div>
        )
    }
}