import React from 'react';

import '../App4_Css/App4.css'

import Cp_banner from '../App4_Js/Cp_banner'
import Cp_CpAPP from '../App4_Js/Cp_CpAPP'
import Cp_Select from '../App4_Js/Cp_Select'
import Footer from "../App1_js/footer";

class App4 extends React.Component{
    render() {
        return (
            <div className="App4">
                    <Cp_banner/>
                    <Cp_CpAPP/>
                    <Cp_Select/>

                    <Footer/>
            </div>
        );
    }
}

export default App4;
