import React, {Component} from 'react';

import "../App3_css/S4_Li.css"

import title_Li from '../images/img3/title_Li.png'
import Libanner2 from '../images/img3/Libanner2.jpg'

export default class S4_Li extends Component {
    render() {
        return (
            <div id="S4_Li" class="S4_box" style={{display:this.props.Li}}>

                <div class="S4_Li_Curtain lazy_bg" data-original={Libanner2}>
                    <div className="">
                        <h3 >
                            Our Core Competency — High-performance LFP Cathode Material
                        </h3>

                    </div>
                </div>
                <div class="S4_Li_content ">
                    <div class="S4_Li_box">
                        <div className="S1_top S4_Li_top">

                            <div class="S4_Li_top_title ">
                                <h3 >The LFP Cathode is the Key to Advancing Lithium-Ion Technology.</h3>
                                <p>
                                    Our high-performance LFP cathode powders are engineered to help extend cycle life, rate capability and low temperature performance in lithium iron phosphate batteries. Our LFP cathode offers several key advantages including a high current rating, long cycle life, and superior thermal stability, which makes it one of the safest and most abuse-tolerant cathode material.
                                </p>
                            </div>
                        </div>
                        <div class="S4_Li_bot" >
                            <ul>
                                <li >
                                    <h3 >Safe use</h3>
                                    <p >
                                        Lithium iron phosphate can solve the safety problem of lithium cobaltate and lithium manganate. Lithium iron phosphate undergoes rigorous safety testing and will not explode even in the worst traffic accidents.
                                    </p>
                                </li>
                                <li>
                                    <h3>Long life</h3>
                                    <p>
                                        Lithium iron phosphate is a power battery for the positive electrode material, and the cycle life can reach 2000 times or more, which can reach 2000 times. Lithium iron phosphate battery can be used for 7 to 8 years.
                                    </p>
                                </li>
                                <li>
                                    <h3>Fast charge</h3>
                                    <p>
                                        It can be charged and discharged quickly with high current 2C. Under the special charger, the battery can be fully charged within 1.5 minutes of 1.5C charging, and the starting current can reach 2C.
                                    </p>
                                </li>
                                <li>
                                    <h3>High temperature resistance</h3>
                                    <p>
                                        The peak temperature of lithium iron phosphate can reach 350 ° C ~ 500 ° C and lithium manganate and lithium cobalt oxide can only be around 200 ° C.
                                    </p>
                                </li>
                                <li>
                                    <h3>High capacity</h3>
                                    <p>
                                        The mileage of lithium iron phosphate power battery is 3 to 4 times that of the same quality lead-acid battery, and it can run about 120 kilometers with a single charge.
                                    </p>
                                </li>
                                <li>
                                    <h3>No memory effect</h3>
                                    <p>
                                        Nickel-metal hydride and nickel-cadmium batteries have memory, but lithium iron phosphate batteries do not have this phenomenon. They can be used with charging, and do not need to be discharged before recharging.
                                    </p>
                                </li>
                                <li>
                                    <h3>Lightweight</h3>
                                    <p>
                                        The volume of the lithium iron phosphate battery of the same specification capacity is 2/3 of the volume of the lead-acid battery, and the weight is 1/3 of the lead-acid battery.
                                    </p>
                                </li>
                                <li>
                                    <h3>Environmental protection</h3>
                                    <p>
                                        Lithium iron phosphate material does not cause any pollution to the environment, and is recognized as a green battery. The battery is free from pollution during production and use.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}