import React from 'react';
// import logo from './logo.svg';

import './App.css';

import {  BrowserRouter as Router, Route } from "react-router-dom";

import App1 from './container/App1'
import App2 from "./container/App2";
import App3 from "./container/App3";
import App4 from "./container/App4";
import App5_Global from "./container/App5_Global";
import App6_XW from "./container/App6_XW";
import App7_Contact from "./container/App7_Contact";


import Nav from './App1_js/nav'
import XW_HYZX from "./Js_List/App6_XW_js/XW_HYZX";
import XW_HYZX_01 from "./Js_List/App6_XW_js/XW_HYZX_01";

class App extends React.Component{
    componentWillMount(){



        /*if(mobile_flag){
            window.location.href="https://m.haidienergy.com";
            // $('.now_qq').attr('href',"mqqwpa://im/chat?chat_type=wpa&uin=<?php echo $qq['qq']?>&version=1&src_type=web&web_src=lvlingseeds.com");
        }else{
            window.location.href="https://www.haidienergy.com";
        }
*/
//判断是否是手机

        var userAgentInfo = navigator.userAgent;
        var mobileAgents = [ "Android", "iPhone", "SymbianOS", "Windows Phone", "iPad","iPod"];
        var mobile_flag = false;

        //根据userAgent判断是否是手机
        for (var v = 0; v < mobileAgents.length; v++) {
            if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
                mobile_flag = true;
                break;
            }
        }
        var screen_width = window.screen.width;
        var html_width = document.getElementsByTagName('html')[0].offsetWidth;
        var screen_height = window.screen.height;
        //根据屏幕分辨率判断是否是手机
        if(screen_width < 1100 || html_width<1100){
            mobile_flag = true;
        }

        if(mobile_flag){
            // alert("移动")
            window.location.href="https://m.haidienergy.com";
            // $('.now_qq').attr('href',"mqqwpa://im/chat?chat_type=wpa&uin=<?php echo $qq['qq']?>&version=1&src_type=web&web_src=lvlingseeds.com");
        }

        window.addEventListener("popstate", function(e) {

            if(!mobile_flag){
                    window.location.href="https://www.haidienergy.com";
            }

            window.location.reload();
        })

    }
    render() {
        return (
            <div className="App" >
                <Router >
                        <Route exact path="/"  component={App1} />
                        <Route  path="/about" component={App2} />
                        <Route  path="/research" component={App3} />
                        <Route  path="/product" component={App4} />
                        <Route  path="/global" component={App5_Global} />

                        <Route  path="/news" component={App6_XW} />
                        <Route  path="/industry" component={App6_XW} />
                        <Route  path="/industrys" component={App6_XW}/>
                        {/*<Route  path="/xw_zx" component={XW_HYZX}/>*/}
                        <Route  path="/contact" component={App7_Contact} />
                        <Nav/>
                </Router>

            </div>
        );
    }
}

export default App;
