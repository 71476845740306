import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/DianDongC.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import ddc_banner2 from "../img4/Cp_DianDongC/ddc-banner2.png";

import gefqstc_1 from "../img4/Cp_DianDongC/gefqstc-1.png";
import gefqstc_2 from "../img4/Cp_DianDongC/gefqstc-2.png";
import gefqstc_3 from "../img4/Cp_DianDongC/gefqstc-3.png";

import ddzxc_1 from "../img4/Cp_DianDongC/ddzxc-1.png";
import ddzxc_2 from "../img4/Cp_DianDongC/ddzxc-2.png";
import ddzxc_3 from "../img4/Cp_DianDongC/ddzxc-3.png";

import ly_1 from "../img4/Cp_DianDongC/ly-1.png";
import ly_2 from "../img4/Cp_DianDongC/ly-2.png";
import ly_3 from "../img4/Cp_DianDongC/ly-3.png";

import ylc_1 from "../img4/Cp_DianDongC/ylc-1.png";
import ylc_2 from "../img4/Cp_DianDongC/ylc-2.png";
import ylc_3 from "../img4/Cp_DianDongC/ylc-3.png";

import zdydysc_1 from "../img4/Cp_DianDongC/zdydysc-1.png";
import zdydysc_2 from "../img4/Cp_DianDongC/zdydysc-2.png";
import zdydysc_3 from "../img4/Cp_DianDongC/zdydysc-3.png";

import gefqc_1 from "../img4/Cp_DianDongC/gefqc-1.png";
import gefqc_2 from "../img4/Cp_DianDongC/gefqc-2.png";
import gefqc_3 from "../img4/Cp_DianDongC/gefqc-3.png";

import cc_1 from "../img4/Cp_DianDongC/cc-1.png";
import cc_2 from "../img4/Cp_DianDongC/cc-2.png";
import cc_3 from "../img4/Cp_DianDongC/cc-3.png";

import qc_1 from "../img4/Cp_DianDongC/qc-1.png";
import qc_2 from "../img4/Cp_DianDongC/qc-2.png";
import qc_3 from "../img4/Cp_DianDongC/qc-3.png";

import ddkc_1 from "../img4/Cp_DianDongC/ddkc-1.png";
import ddkc_2 from "../img4/Cp_DianDongC/ddkc-2.png";
import ddkc_3 from "../img4/Cp_DianDongC/ddkc-3.png";
import axios from "axios";
import Domain_Name from "../Js_List/Domain_Name";
import Request_Head from "../Js_List/Request_Head";

export default class DianDongC extends Component {

    constructor(props){
        super(props);
        var Request_Token = Request_Head;
        this.state={
            Token:Request_Token,/*请求头*/
            Golf_Trolley:[],/*高尔夫球手推车*/
            Electric_Bike:[],/*电动自行车*/
            Wheel_Chair:[],/*轮椅*/
            Medical_Car:[],/*医疗车*/
            AGV:[],/*AGV 自动导引运输车*/
            Golf_Cart:[],/*高尔夫球车*/
            Forklift:[],/*叉车*/
            Passenger_Vehicl:[],/*汽车*/
            Electric_Bus:[],/*电动客车*/
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*高尔夫球手推车电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Golf_Trolley:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*电动自行车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'2',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Electric_Bike:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*轮椅电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'4',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Wheel_Chair:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*医疗车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'5',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Medical_Car:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*AGV*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'6',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    AGV:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*高尔夫球车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'7',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Golf_Cart:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*叉车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'8',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Forklift:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*汽车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'9',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Passenger_Vehicl:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*电动客车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'10',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Electric_Bus:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

    }

    render() {
        return (
            <div id="DianDongC" className="S4_box " style={{display: "none"}}>
                <div className="S4_Li_Curtain lazy_bg Cp_top_banner" data-original={ddc_banner2}>
                    <div>
                        <h3 style={{"text-transform":"uppercase"}}>The core of the revolution</h3>
                        <p>
                            The most ideal battery<br/>
                            Safe, compact and lightweight<br/>
                            Large capacity, capable of providing a large amount of energy
                        </p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title">
                        <h3>
                            ELECTRIC VEHICLE BATTERY
                        </h3>
                        <p>

                            Improve product performance beyond the limits of current business models,<br/>
                            Create a new energy environment by adopting the latest battery technology.

                        </p>
                    </div>
                    {/*高尔夫推车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={gefqstc_1}>
                                            </div>
                                            <div className="bai">
                                                <h3>
                                                    Golf Trolley Battery
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>
                                                        Advanced welding technology, proven process, higher quality and reliability
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={gefqstc_2} alt=""/></div>
                                                    <div><img data-original={gefqstc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>The best companion for golf</h3>
                                                        <p>
                                                            The golf cart battery is designed for use in most golf carts in a lightweight, elegant design. Extremely long cycle life and only 1/4 weight lead acid battery
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Energy(Wh)</td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Golf_Trolley.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*自行车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ddzxc_1}>
                                            </div>
                                            <div >
                                                <h3>
                                                    Electric Bike Battery
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>
                                                        Good battery sealing performance, strong charge acceptance and good vibration resistance
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ddzxc_2} alt=""/></div>
                                                    <div><img data-original={ddzxc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Long life - lasting power</h3>
                                                        <p>
                                                            Manufactured with lightweight and ultra-thin battery packs, it has strong adaptability and can improve driving distance.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Electric_Bike.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>

                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*轮椅*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ly_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    Wheel Chair Battery
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>

                                                        Large capacity battery, Provide long-lasting battery life,<br/>
                                                        small details to enhance product quality

                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ly_2} alt=""/></div>
                                                    <div><img data-original={ly_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Strong "core" - providing sufficient energy</h3>
                                                        <p>
                                                            The large-capacity battery provides a more powerful energy source and provides a comfortable experience for users.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Energy(Wh)</td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>

                                <tbody>
                                {this.state.Wheel_Chair.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*医疗车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ylc_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    Medical Car Battery
                                                </h3>
                                                <ul style={{width:"70%"}}>
                                                    <li>

                                                        Strict design and production, large capacity, good stability
                                                        To meet workflow needs.

                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ylc_2} alt=""/></div>
                                                    <div><img data-original={ylc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>For medical research and development</h3>
                                                        <p>
                                                            The Haidi research team is based on the technical strength of the battery products designed according to the specific medical vehicle model.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Energy(Wh)</td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Medical_Car.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*运输车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={zdydysc_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    Autonnomously Guided Vehicles(AGVs)
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>

                                                        Intelligent battery equipment battery<br/>
                                                        Equipped with a battery management system to ensure performance

                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={zdydysc_2} alt=""/></div>
                                                    <div><img data-original={zdydysc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Innovative R&D is only for efficiency</h3>
                                                        <p>
                                                            The battery has the most stable high-performance performance, and contributes to the intelligent production line with its own professional technology.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Energy(Wh)</td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.AGV.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*高尔夫球车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={gefqc_1}>
                                            </div>
                                            <div className="bai">
                                                <h3>
                                                    Golf Cart Battery
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>

                                                        Good battery sealing performance and<br/>
                                                        strong charging acceptance Good vibration resistance.

                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={gefqc_2} alt=""/></div>
                                                    <div><img data-original={gefqc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Golf cart manufacturer preferred</h3>
                                                        <p>
                                                            The battery can meet the needs of users such as safety, reliability, long battery life, etc. It can be widely used in recreational vehicles such as golf carts.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Energy(Wh)</td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Golf_Cart.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*叉车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={cc_1}>
                                            </div>
                                            <div className="bai">
                                                <h3>
                                                    Forklift Battery
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>Long service life</li>
                                                    <li>reliable sealing structure</li>
                                                    <li>Short-circuit proof design</li>
                                                    <li>dustproof and waterproof advantages</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={cc_2} alt=""/></div>
                                                    <div><img data-original={cc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Powerful - durable</h3>
                                                        <p>
                                                            Carefully designed to prevent acid leakage; strong recharge after deep discharge.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Energy(Wh)</td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Forklift.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*汽车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={qc_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    Passenger Vehicle Battery
                                                </h3>
                                                <ul style={{width:"90%"}}>
                                                    <li>

                                                        Battery for electric vehicles requires high safety, high output capability, high energy density, etc.
                                                        Haidi Chemical's products have been proven to fully satisfy this performance and quality

                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={qc_2} alt=""/></div>
                                                    <div><img data-original={qc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Provide a safe and reliable drive experience</h3>
                                                        <p>
                                                            For new energy vehicles, the power battery system is the core component of the product to meet high energy density, long cruising range, safe and reliable.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Energy(Wh)</td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Passenger_Vehicl.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*电动客车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ddkc_1}>
                                            </div>
                                            <div className="bai">
                                                <h3>
                                                    Electric Bus Battery
                                                </h3>
                                                <ul style={{width:"70%"}}>
                                                    <li>

                                                        The design of the battery is focused on
                                                        Pursuit of optimal resilience and longest life

                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ddkc_2} alt=""/></div>
                                                    <div><img data-original={ddkc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Green energy - building a better life</h3>
                                                        <p>
                                                            Battery-equipped battery management system ensures performance and safety, high-quality batteries ensure consistent vehicle performance
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Energy(Wh)</td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Electric_Bus.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}