import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"

import Ji_1 from '../images/img3/Ji-01.jpg'
import Ji_2 from '../images/img3/Ji-02.jpg'
import Ji_3 from '../images/img3/Ji-03.jpg'
import Ji_4 from '../images/img3/04.png'

export default class S4_Ji extends Component {
    render() {
        return (
            <div id="S4_Ji" class="S4_box" style={{display:'none'}}>
                <div className="S4_Ji_Curtain" >
                    <div >
                        <h3 style={{"text-transform":"capitalize"}}>Advanced Battery Solution Provider</h3>
                        <p>
                            Our Engineers have rich experiences in a variety of lithium battery projects and their commit to supporting our customers’ for each individual case with the knowledge ,skill and ability to deliver the most advanced and practical battery solutions efficiently.
                        </p>
                    </div>
                </div>
                <div className="S4_Ji_content">
                    <div className="S4_Ji_box">
                        <div className="S1_top S4_Ji_top">
                            <div className="S4_Ji_top_title wow fadeInUp bg-green" data-wow-delay="1s">
                                <h3 >We strive to be your one-stop Lithium Ion battery solution provider.</h3>
                                <p>
                                    Since 2005, we have been focusing on developing tailored battery solutions and our lithium battery cells and integrated battery systems have been proved to be the safest and most cost competitive in the industry.<br/>
                                    To meet unique requirements from our OEM partners, our engineering team can develop customized hardware and software solutions for specific applications.


                                </p>
                            </div>
                            <div className="S4_Ji_TitleImg">
                            </div>
                        </div>
                        <div className="S4_Ji_bot">
                            <ul className="flex_list">
                                <li class=" wow slideInUp bg-green" data-wow-delay="1s" data-wow-duration="1s">
                                    <div class="S4_Ji_img">
                                        <img src={Ji_1} alt=""/>
                                    </div>
                                    <p>
                                        LFP Battery Solution
                                    </p>
                                </li>
                                <li class=" wow slideInUp bg-green" data-wow-delay="1.2s" data-wow-duration="1s">
                                    <div class="S4_Ji_img ">
                                        <img src={Ji_2} alt=""/>
                                    </div>
                                    <p>NMC Battery Solution
                                    </p>
                                </li>
                                <li class=" wow slideInUp bg-green" data-wow-delay="1.4s" data-wow-duration="1s">
                                    <div class="S4_Ji_img">
                                        <img src={Ji_3} alt=""/>
                                    </div>
                                    <p>
                                        Integrated System Solutions

                                    </p>
                                </li>
                                <li class=" wow slideInUp bg-green" data-wow-delay="1.6s" data-wow-duration="1s">
                                    <div class="S4_Ji_img">
                                        <img src={Ji_4} alt=""/>
                                    </div>
                                    <p>
                                        ESS Solution

                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}