import React, {Component} from 'react';

import "../App1_css/chuang.css"

import Chuang_bg from "../images/img/Ybanner2.png";

export default class Chuang extends Component {
    render() {
        return (
            <div id="chuang">
                <div class="lazy cimg lazy_bg" data-original={Chuang_bg}></div>
                <div class="cu">
                    <h3 style={{"text-transform":"uppercase"}}>GAME-CHANGING CAPABILITY<br/> FOR BATTERY PRODUCTION</h3>
                    <p>
                        Our large scale lithium battery production line incorporate Haidi’s patented technology,consisting of electrode formation, stacking, inspection, packaging,and shipping process.
                    </p>
                </div>

            </div>
        )
    }
}