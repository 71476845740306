import React, {Component} from 'react';

import "../../Css_List/App7_Contact_css/App7_Contact.css"
import banner1 from '../../images/img7_Contact/banenr1.png'

import Logo from "../../App1_js/logo";

export default class Banner extends Component {
    render() {
        return (
            <div  className="lazy_bg Contact_banner" data-original={banner1}>
                <Logo/>
                <div className="Banner_title Contact_title wow fadeInLeft bg-yellow">


                    <h3 style={{"text-transform":"uppercase"}}>Joining future of Haidi is boundless</h3>
                    <p>
                        Talent is the central force in the development of Haidi.<br/>
                        If you have a high vision and a dream, you are determined to do things and create value.
                    </p>
                </div>
            </div>
        )
    }
}