import React, {Component} from 'react';

import "../App3_css/Section_3.css"
import "../App4_Css/Cp_CpAPP.css"

export default class Cp_CpAPP extends Component {
    render() {
        return (
            <div id="Cp_CpAPP">
                <div class="S3_box">
                    <div class="wow fadeInUp bg-yellow S1_top S3_box_top"  data-wow-delay="1s" data-wow-duration="1s">
                        <h3 class="Cp_CpAPP_h3">Products & Applications</h3>
                        <p>
                            After years of success in the global market, our R&D team is dedicated to continual product development to
                            improve the performance reliability of our product range. We have an extensive range of lithium batteries for
                            different kinds of applications
                        </p>
                    </div>
                    <div class="S3_box_bot Cp_CpAPP_bot">
                        <ul>
                            <li>
                                <div class="S3_li_img S4_li_T1"></div>
                                <p><span>Read More</span>BATTERY CELL</p>
                            </li>
                            <li>
                                <div class="S3_li_img S4_li_T2"></div>
                                <p><span>Read More</span>Module</p>
                            </li>
                            <li>
                                <div class="S3_li_img S4_li_T3"></div>
                                <p><span>Read More</span>POWER TOOL BATTERY</p>
                            </li>
                            <li>
                                <div className="S3_li_img S4_li_T4"></div>
                                <p><span>Read More</span> LEAD ACID REPLACEMENT BATTERY</p>
                            </li>
                            <li>
                                <div className="S3_li_img S4_li_T5"></div>
                                <p><span>Read More</span>ELECTRIC VEHICLE BATTERY</p>
                            </li>
                            <li>
                                <div className="S3_li_img S4_li_T6"></div>
                                <p><span>Read More</span>ENERGY STORAGE SYSTEM</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}