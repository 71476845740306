import React, {Component} from 'react';

import "../App3_css/Section_3.css"

import Section3 from '../images/img3/Section_3.png'
import Raw_material from '../images/img3/s-02/Raw_material.png'
import Process_development from '../images/img3/s-02/Process_development.png'
import Battery_bg from '../images/img3/s-02/Battery-bg.png'
import Module_design from '../images/img3/s-02/Module_design.png'
import Assembly from '../images/img3/s-02/Assembly.png'
import Finish_shipping from '../images/img3/s-02/Finish_shipping.png'

export default class Section_3 extends Component {
    render() {
        return (
            <div id="Section_3">

                <div class="S3_Top lazy_bg" data-original={Section3} >
                    <div >
                        <h3 >6 Key Advantages To Delivering Advanced Batteries</h3>
                    </div>
                </div>
                <div class="S3_box "  >

                    <div class="S1_top S3_box_top" >
                        <h3 >WHY WE ARE THE BEST?</h3>
                        <p>
                            As a trusted high-volume manufacturer of lithium cells and packs for more than 15 years, we at Haidi Energy constantly strengthen and shape ourselves to fit the changing needs of the industry and our clients. Our advanced manufacturing ,experienced engineering, quality control through experiences and hassle-free logistic services give us the advantage of becoming one of the best manufacturer in the lithium battery industry.

                        </p>
                    </div>
                    <div class="S3_box_bot">
                        <ul>
                            <li >
                                {/*<div class="S3_li_img S3_li_T1"></div>*/}
                                <img className="lazy" data-original={Raw_material}></img>

                                <p>
                                    In house cathode
                                    material production
                                </p>
                            </li>
                            <li>
                                {/*<div class="S3_li_img S3_li_T2"></div>*/}
                                <img className="lazy" data-original={Process_development}></img>
                                <p>State-of-art production facility
                                </p>
                            </li>
                            <li>
                                {/*<div class="S3_li_img S3_li_T3"></div>*/}
                                <img className="lazy" data-original={Battery_bg}></img>
                                <p>Experienced Engineer team
                                </p>
                            </li>
                            <li>
                                {/*<div className="S3_li_img S3_li_T4"></div>*/}
                                <img className="lazy" data-original={Module_design}></img>
                                <p>Quality control through
                                    experiences
                                </p>
                            </li>
                            <li>
                                {/*<div className="S3_li_img S3_li_T5"></div>*/}
                                <img className="lazy" data-original={Assembly}></img>
                                <p>Fully automatic
                                    production line
                                </p>
                            </li>
                            <li>
                                {/*<div className="S3_li_img S3_li_T6"></div>*/}
                                <img className="lazy" data-original={Finish_shipping}></img>
                                <p>Hassle-free Shipping</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}