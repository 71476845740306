import React from "react";

import "../App1_css/zixun.css"

import { Link } from "react-router-dom";

import {Container,Row,Col,Card} from 'react-bootstrap'

import tu528 from '../images/img/tu528.jpg'
import zx532 from '../images/img/zxt532.png'
import zx536 from '../images/img/zx536@2x.jpg'
import latest_information from '../images/img/latest-information.png'
import Footer from "./footer";
import axios from "axios";
import Domain_Name from "../Js_List/Domain_Name";
import Request_Head from "../Js_List/Request_Head";


class Zixun extends React.Component{
    constructor(props){
        super(props);

        /*请求头*/
        var Request_Token = Request_Head;

        this.UrlA = this.UrlA.bind(this)

        this.state={
            Token:Request_Token,/*请求头*/
            hd_xw_tyle:[],/*公司新闻*/
            hy_zx_tyle:[],/*行业资讯*/
            hd_id:'',/*公司新闻  ID*/
            hy_id:'',/*行业资讯  ID*/
            hd_xw:[],/*公司新闻  数据*/
            hy_zx:[],/*行业资讯  数据*/
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        axios.post(Domain_head+'/api/category/data',{
            id: '',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    hd_xw_tyle:response.data.data[0],
                    hy_zx_tyle:response.data.data[1],
                    hd_id:response.data.data[0].id,
                    hy_id:response.data.data[1].id,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });


        setTimeout(function () {

            /*获取公司新闻数据*/
            axios.post(Domain_head+'/api/article/data',{
                cid: "1",
                lang_type:'2',
                Token:_this.state.Token
            })
                .then(function (response) {

                    var xw_data = response.data.data.data;
                    var list = [];
                    var list_no = [];

                    for(let i = 0;i<xw_data.length;i++){
                        if(xw_data[i].is_hot==1){
                            list.push(xw_data[i]);
                        }else {
                            list_no.push(xw_data[i]);
                        }
                    }

                    if(list==''){
                        list=list_no;
                    }

                    _this.setState({
                        hd_xw:list,
                        isLoaded:true
                    });

                })
                .catch(function (error) {
                    console.log(error);

                    _this.setState({
                        isLoaded:false,
                        error:error
                    })
                });

            /*获取行业资讯*/
            axios.post(Domain_head+'/api/article/data',{
                cid: _this.state.hy_zx,
                lang_type:'2',
                Token:_this.state.Token
            })
                .then(function (response) {

                    var zx_data = response.data.data.data;
                    var zx_list = [];
                    var zx_list_no = [];

                    for(let i = 0;i<zx_data.length;i++){
                        if(zx_data[i].is_hot==1){
                            zx_list.push(zx_data[i])
                        }else{
                            zx_list_no.push(zx_data[i])
                        }
                    }

                    if(zx_list==''){
                        zx_list=zx_list_no;
                    }
                    
                    _this.setState({
                        hy_zx:zx_list,
                        isLoaded:true
                    });
                })
                .catch(function (error) {
                    console.log(error);

                    _this.setState({
                        isLoaded:false,
                        error:error
                    })
                });
        },1000)

    }


    UrlA(){
        setTimeout(function () {
            window.location.reload();
        },100)
    }
    render() {
        return(
            <div id="zx" className="mousewheel_footer">
                <div class="zx_box lazy_bg" data-original={latest_information}>
                    <div class="box_head">
                        <div class="box_h3">
                            <h3 style={{"text-transform":"uppercase"}}>Latest information</h3>
                            <p>news center</p>
                        </div>
                    </div>
                    <div class="box_content" >
                        <Container>
                            <Row>
                                {/*海帝新闻*/}
                                <Col sm={4} md={4} lg={4}>
                                    <div class="col_1">
                                        <p class="p_1">Company News</p>

                                        {this.state.hd_xw.map((data,i)=>{

                                            var date = data.create_time;
                                            var date_arr = date.split(" ");
                                            var date_duan_arr = date_arr[0].split("-");

                                            //day 日
                                            var date_day = date_duan_arr[date_duan_arr.length-1];
                                            date_duan_arr.pop();

                                            //Years 年月
                                            var date_Years = date_duan_arr.join(".");

                                            if(i==0){

                                                var data_id = data.id;
                                                return(
                                                    <Link to={"/news/activity_"+data_id} key={i} onClick={this.UrlA}>
                                                        <Card style={{ width: '100%' }} key={i}>
                                                            <div className="c1_img">
                                                             {/*   <Card.Img className="lazy" variant="top"  src={data.image}/>*/}
                                                                <div className="zx_list_img" style={{"background-image":"url("+data.image+")","height":"100%"}}></div>
                                                            </div>
                                                            <Card.Body>
                                                                <div className="content" style={ {"-webkit-box-orient":'vertical'}}>
                                                                    <Card.Title style={{"-webkit-box-orient":"vertical"}}>
                                                                        {data.title}
                                                                    </Card.Title>
                                                                    <Card.Text style={ {"-webkit-box-orient":'vertical'}}>
                                                                        {data.summary}
                                                                    </Card.Text>
                                                                </div>
                                                                <div className="date">
                                                                    <div>
                                                                        <h3>{date_day}</h3>
                                                                        <p>{date_Years}</p>
                                                                    </div>
                                                                    <span></span>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Link>
                                                )
                                            }
                                        })}
                                    </div>
                                </Col>
                                {/*海帝新闻列表*/}
                                <Col sm={4} md={4} lg={4} >
                                    <div class="col_2">
                                        {this.state.hd_xw.map((data,i)=>{
                                            if(i==1){
                                                var date = data.create_time;
                                                var date_arr = date.split(" ");
                                                var date_duan_arr = date_arr[0].split("-");

                                                //day 日
                                                var date_day = date_duan_arr[date_duan_arr.length-1];

                                                date_duan_arr.pop();
                                                //Years 年月
                                                var date_Years = date_duan_arr.join(".");

                                                var data_id = data.id;
                                                return(

                                                    <Link to={"/news/activity_"+data_id} key={i} onClick={this.UrlA} >
                                                        <div className="c2_1" key={i}>
                                                            <div className="box1">
                                                                <div className="right">
                                                                    <h3>{date_day}</h3>
                                                                    <p>{date_Years}</p>
                                                                </div>
                                                                <div className="center">
                                                                    <p style={{"-webkit-box-orient": 'vertical'}}>
                                                                        {data.title}
                                                                    </p>
                                                                </div>
                                                                <div className="left">
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="img">
                                                                <div className="zx_list_img" style={{"background-image":"url("+data.image+")"}}></div>
                                                                {/*<img className="lazy" data-original={data.image}></img>*/}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            }

                                        })}
                                        {this.state.hd_xw.map((data,i)=>{
                                            if(i==2){
                                                var date = data.create_time;
                                                var date_arr = date.split(" ");
                                                var date_duan_arr = date_arr[0].split("-");

                                                //day 日
                                                var date_day = date_duan_arr[date_duan_arr.length-1];

                                                date_duan_arr.pop();
                                                //Years 年月
                                                var date_Years = date_duan_arr.join(".");

                                                var data_id = data.id;

                                                return(
                                                    <Link to={"/news/activity_"+data_id} key={i}  onClick={this.UrlA}>
                                                        <div className="c2_1" key={i}>
                                                            <div className="box1">
                                                                <div className="right">
                                                                    <h3>{date_day}</h3>
                                                                    <p>{date_Years}</p>
                                                                </div>
                                                                <div className="center">
                                                                    <p style={{"-webkit-box-orient": 'vertical'}}>
                                                                        {data.title}
                                                                    </p>
                                                                </div>
                                                                <div className="left">
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                            <div className="img">
                                                                <div className="zx_list_img" style={{"background-image":"url("+data.image+")"}}></div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            }

                                        })}
                                    </div>
                                </Col>
                                {/*行业资讯*/}
                                <Col sm={4} md={4} lg={4}>
                                    <div class="col3">
                                        <p class="p_1">Industry News</p>
                                        <div className="c2_2">
                                            {this.state.hy_zx.map((data,i)=>{
                                                var date = data.create_time;
                                                var date_arr = date.split(" ");
                                                var date_duan_arr = date_arr[0].split("-");

                                                //day 日
                                                var date_day = date_duan_arr[date_duan_arr.length-1];

                                                date_duan_arr.pop();
                                                //Years 年月
                                                var date_Years = date_duan_arr.join(".");

                                                if(i<4){
                                                    var data_id = data.id;

                                                    return(
                                                        <Link to={"/industrys/information_"+data_id} key={i} onClick={this.UrlA}>
                                                            <div className="box1">
                                                                <div className="right">
                                                                    <div>
                                                                        <h3>{date_day}</h3>
                                                                        <p>{date_Years}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="center">
                                                                    <p style={{"-webkit-box-orient": "vertical"}}>{data.title}</p>
                                                                </div>
                                                                <div className="left">
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div class="box_footer" >
                        <Link to="/news" onClick={this.UrlA}>Read More</Link>
                    </div>
                    
                </div>
                <Footer/>
            </div>
        )
    }
}
export default Zixun;
