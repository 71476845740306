import React, {Component} from 'react';

import "../../Css_List/App6_XW_css/banner.css"
import banner1 from '../../images/img6_XW/banner1.png'

import Logo from "../../App1_js/logo";

export default class Banner extends Component {
    render() {
        return (
            <div  className="lazy_bg XW_banner" data-original={banner1} style={{"background-attachment": "fixed"}}>
                <Logo/>
                <div className="Banner_title Banner_XW_title wow bounceInLeft bg-green">
                    <h3 style={{"text-transform":"uppercase"}}>haidi news</h3>
                </div>
            </div>
        )
    }
}