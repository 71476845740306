import React, {Component} from 'react';

import "../../Css_List/App6_XW_css/XW_activity_01.css"


import XW_01_img_1 from '../../images/img6_XW/XW_01_img-1.png'
import Link_a from './Link_a'
import axios from "axios";
import Domain_Name from "../Domain_Name";
import Request_Head from "../Request_Head";

import XW_select from "./XW_select";
import Banner_news from "./banner_news";

export default class XW_activity_01 extends Component {
    constructor(props) {
        super(props);
        var Request_Token = Request_Head;
        this.state = {
            Token:Request_Token,/*请求头*/
            activity:[],
            num_id:'',
            num_next:'',
            num_prev:''
        }
        this.axiso_F = this.axiso_F.bind(this)
    }
    componentWillMount () {
        var Domain_head = Domain_Name;
        const _this = this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
        var num = _this.props.match.params.mode.split("_");
        var num_id = num[1]
       
        /*当前页数据*/
        axios.post(Domain_head+'/api/article/content', {
            article_id:num_id,
            lang_type:'2',
            Token:this.state.Token
        })
            .then(function (response) {
                var data_next = response.data.data.next;
                var data_prev = response.data.data.prev;


                _this.setState({
                    //总数据
                    activity:response.data.data,
                    num_id:num_id,
                    num_next:data_next,
                    num_prev:data_prev,
                    isLoaded: true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded: false,
                    error: error
                })
            });

    }

    axiso_F (){
        var Domain_head = Domain_Name;
        const _this = this;
        axios.post(Domain_head+'/api/article/content', {
            article_id:this.state.num_id,
            Token:this.state.Token
        })
            .then(function (response) {

                _this.setState({
                    //总数据
                    activity:response.data.data,
                    num_id:this.state.num_id,
                    isLoaded: true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded: false,
                    error: error
                })
            });
    }

    render() {
        var conten = this.state.activity.content;
        return (
            <div>
                <Banner_news/>
                <XW_select/>
                <div className="XW_activity_01">

                    <div id="Section_4 " className="XW_select">

                        <div className="XW_activity_box">
                            <div>
                                <h1>{this.state.activity.title}</h1>
                                <p>Release time：{this.state.activity.create_time}</p>
                                <div className="XW_activity_content">
                                    <div dangerouslySetInnerHTML={{ __html: conten}} />
                                </div>
                                <div className="XW_activity_list">

                                    <p><span>Last one: </span><Link_a path={"/news/activity_"+this.state.num_prev.id} text={this.state.num_prev.title}></Link_a></p>
                                    <p className="XW_activity_list_return">
                                        <Link_a path={"/news"} text={"Return list"}></Link_a>
                                    </p>
                                    <p><span>Next one: </span><Link_a  path={"/news/activity_"+this.state.num_next.id} text={this.state.num_next.title}></Link_a></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}