import React from 'react';

import '../Css_List/App5_Global_css/App5.css'


import { BrowserRouter as Router, Route } from "react-router-dom";

import Footer from "../App1_js/footer";
import XW_activity from "../Js_List/App6_XW_js/XW_activity";
import XW_activity_01 from "../Js_List/App6_XW_js/XW_activity_01";
import XW_activity_02 from "../Js_List/App6_XW_js/XW_activity_02";
import XW_activity_03 from "../Js_List/App6_XW_js/XW_activity_03";
import XW_HYZX from "../Js_List/App6_XW_js/XW_HYZX";
import XW_HYZX_01 from "../Js_List/App6_XW_js/XW_HYZX_01";


class App6_XW extends React.Component{
    render() {
        return (
            <div className="App6">
                <Router>
                    <Route exact path="/news" component={XW_activity}/>
                    <Route  path="/news/:mode" component={XW_activity_01} />

                    <Route  path="/industry" component={XW_HYZX}/>
                    <Route  path="/industrys/:mode" component={XW_HYZX_01}/>
                </Router>
                <Footer/>
            </div>
        );
    }
}

export default App6_XW;