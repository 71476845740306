import React from 'react';

import '../App3_css/App3.css'

import Banner from '../App3_js/Banner'
import Section_1 from '../App3_js/Section_1'
import Section_2 from '../App3_js/Section_2'
import Section_3 from '../App3_js/Section_3'
import Section_4 from '../App3_js/Section_4'
import Footer from '../App1_js/footer'


class X extends React.Component{
    render() {
        return (
            <div className="App3">
                <Banner/>
                <Section_1/>
                <Section_4/>
                <Section_3/>
                <Section_2/>

                <Footer/>
            </div>
        );
    }
}

export default X;
