import React from 'react';
// import logo from './logo.svg';

import '../App1_css/App1.css';

import Section1 from '../App1_js/section-1';

import Xing from '../App1_js/xing';
import Chuang from '../App1_js/chuang';
import Li from '../App1_js/Li';
import Zixun from '../App1_js/zixun'

class App1 extends React.Component{
    /*componentWillMount(){
        var url = window.location.href;
        var arrUrl = url.split("/");
        var oFUrl = arrUrl[arrUrl.length-1];
        var aFUrl = oFUrl.split("#");

        var one_off = sessionStorage.getItem("one_off");


    }*/
    render() {
        return (
            <div className="App1" >
                <header className="App-header">
                    <section className="section-wrap">
                        <div className="section section-1">
                            <Section1/>
                        </div>
                        <div className="section section-2">
                            <Xing/>
                        </div>
                        <div className="section section-3">
                            <Chuang/>
                        </div>
                        <div className="section section-4">
                            <Li/>
                        </div>
                        <div className="section section-5">
                            <Zixun/>
                        </div>
                        {/*<div className="section section-7">
                            <Footer/>
                        </div>*/}
                    </section>
                    <ul className="section-btn">
                        <li className="on"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    {/*<Section1/>
                    <Xing/>
                    <Chuang/>
                    <Li/>
                    <Zixun/>
                    <Footer/>*/}
                </header>
            </div>
        );
    }
}

export default App1;
