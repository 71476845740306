import React, {Component} from 'react';

import "../App3_css/Banner.css"
import banner1 from '../images/img3/banner1.jpg'

import Logo from "../App1_js/logo";
import banner_titly from "../images/img3/banner_titly.png";

export default class Banner extends Component {
    render() {
        return (
            <div id="Banner" class="app3_banner lazy_bg" data-original={banner1} style={{"background-attachment": "fixed"}}>
                <Logo/>
                <div class="Banner_title " data-scroll-reveal="enter bottom over 1s and move 200px after">

                    <h3>GLOBAL LEADING LITHIUM BATTERY MANUFACTURER</h3>
                    <div>
                        <p >
                            We adopt factory-of-the-future concepts to achieve operational excellence. Our practical knowledge of lithium technology and years of  experience in cell production delivers a quality battery that could meet the most demanding requirement.
                        </p>
                        {/*<p>Advanced production equipment</p>*/}
                    </div>
                </div>
            </div>
        )
    }
}