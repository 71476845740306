import React from "react";

import { Link } from "react-router-dom";

import '../App1_css/nav.css'

import logo_nav from '../images/img/logo_nav.png'
import logo_nav_w from '../images/img/logo_nav_w.png'
import Close from '../images/img/Close.png'


class Nav extends React.Component{
    constructor(){
        super();
        this.state = {name:'Hello world!'};
        this.handleClick = this.handleClick.bind(this)
        this.LinkClick = this.LinkClick.bind(this)
        this.UrlA = this.UrlA.bind(this)
    }

    handleClick(e) {
        var Ocontent = document.getElementsByClassName('nav_content')[0];
        var Onav = document.getElementById('nav');
        var Ologo = document.getElementsByClassName('logo')[0];
        var OcontentMarginL=parseInt(getComputedStyle(Ocontent).width ) +10;
        var OlogoD=getComputedStyle(Ologo).display;

        if (OlogoD==='none') {
            Ocontent.style.marginLeft = -OcontentMarginL+"px";
            Onav.style.marginLeft = -OcontentMarginL+"px";
            setTimeout(function () {
                Ologo.style.display ="block";
            },500);

        }else {
            Ocontent.style.marginLeft = 0 + "px";
            Onav.style.marginLeft = 0 + "px";
            Ologo.style.display ="none";
        }


        // alert(444)
    }

    LinkClick (section,i){
        setTimeout(function () {
            window.location.reload();
        },100)
    }
    UrlA(a,section,index){
        var url = window.location.href;
        var arrUrl = url.split("/");
        var oFUrl = arrUrl[arrUrl.length-1];
        var aFUrl = oFUrl.split("#");

        if(aFUrl!=a){
            this.LinkClick(section);
        }
    }
    render() {
        return(
            <div id="NAV" onClick={(e)=>{e.nativeEvent.stopImmediatePropagation();}}>
                {/*搜索*/}
                <div className="sou sou_two">
                    <a className="serch" href="http://en.haidienergy.com"/>
                    <form className="form">
                        <input type="text" name="keyword" placeholder="请输入关键字"/>
                    </form>
                    <span> | </span>
                    <a href="https://www.haidienergy.com/zh">CN</a>
                </div>

                {/*左侧导航*/}
                <div id="nav">
                    <div className="logo">
                        <div className="l_click" onClick={(e)=>this.handleClick(e)}>
                            <div className="l_img">
                                <img src={logo_nav}  data-original={logo_nav}/>
                            </div>
                            <div className="l_span"  >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className="nav_content">
                        <div className="list">
                            <div>
                                <div className="list_img">
                                    <div>
                                        <img src={logo_nav_w} data-original={logo_nav_w}/>
                                    </div>
                                    <div>
                                        <img src={Close} data-original={Close} onClick={this.handleClick.bind(this)}/>
                                    </div>
                                </div>
                                <dl className="list_dl">

                                    <dt><Link to="/" onClick={this.LinkClick.bind(this)}><span></span>Home </Link></dt>

                                    <dd>
                                        <ul className="gyhd">
                                            <li><Link to="/about" onClick={this.LinkClick.bind(this)}><span></span>About Haidi </Link>
                                            </li>
                                            {/*<li><Link to="/about">关于海帝</Link></li>*/}
                                            <li><Link to="/about" onClick={this.UrlA.bind(this, "about", "section", "1")}>Introduction</Link></li>
                                            <li><Link to="/about" onClick={this.UrlA.bind(this, "about")}>History</Link></li>
                                            <li><Link to="/about" onClick={this.UrlA.bind(this, "about")}>Philosophy</Link></li>
                                            <li><Link to="/about" onClick={this.UrlA.bind(this, "about")}>Service Area</Link></li>
                                            <li><Link to="/about" onClick={this.UrlA.bind(this, "about")}>Honor</Link></li>
                                        </ul>
                                    </dd>
                                    <dd>
                                        <ul className="JiShu_nav">
                                            {/*<li><Link to="/research" onClick={()=>{this.location.reload()}}>技术研究</Link></li>*/}
                                            <li><Link to="/research" onClick={this.LinkClick.bind(this)}><span></span>Technical</Link></li>
                                            <li><Link to="/research" onClick={this.UrlA.bind(this, "research", "Section_4")}>Advantage</Link></li>

                                            <li><Link to="/research" onClick={this.UrlA.bind(this, "research", "Section_4")}>LiFePO4 MSDS</Link></li>
                                            <li><Link to="/research" onClick={this.UrlA.bind(this, "research")}>Power Solution</Link></li>
                                            <li><Link to="/research" onClick={this.UrlA.bind(this, "research")}>Solid State Battery r&d</Link></li>

                                        </ul>
                                    </dd>
                                    <dd className="ChanP_dd">
                                        <ul className="ChanP_nav">
                                            <li><Link to="/product" onClick={this.LinkClick.bind(this)}><span></span>Products</Link>
                                            </li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this, "product")}>Solutions</Link></li>

                                            <li><Link to="/product" onClick={this.UrlA.bind(this, "product")}>Battery Cell</Link>
                                            </li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this, "product")}>Module</Link></li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this, "product")}>Power Tool Battery</Link></li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this, "product")}>Lead Acid Replacement Battery</Link></li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this, "product")}>Electric Vehicle Battery</Link></li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this, "product")}>Energy Storage System</Link></li>
                                        </ul>
                                    </dd>
                                    <dd>
                                        <ul className="QuanQFW_nav">
                                            <li><Link to="/global" onClick={this.LinkClick.bind(this)}><span></span>Global Service</Link></li>
                                            <li><Link to="/global" onClick={this.UrlA.bind(this, "global", "Section_4")}>Service Concept</Link></li>
                                            <li><Link to="/global"
                                                      onClick={this.UrlA.bind(this, "global", "Section_4")}>Global Network</Link>
                                            </li>
                                            <li><Link to="/global" onClick={this.UrlA.bind(this, "global")}>Branch Contact</Link></li>

                                        </ul>
                                    </dd>
                                    <dd>
                                        <ul className="news_nav">
                                            <li><Link to="/news" onClick={this.LinkClick.bind(this)}><span></span>News</Link></li>
                                            <li><Link to="/news" onClick={this.UrlA.bind(this, "xw")}>Company News</Link>
                                            </li>
                                            <li><Link to="/industry" onClick={this.UrlA.bind(this, "xw")}>Industry News</Link>
                                            </li>
                                        </ul>
                                    </dd>
                                    <dd>
                                        <ul className="Contact_nav">
                                            <li><Link to="/contact" onClick={this.LinkClick.bind(this)}><span></span>Contact us</Link></li>
                                            <li><Link to="/contact"
                                                      onClick={this.UrlA.bind(this, "contact")}>Recruitment</Link></li>
                                            <li><Link to="/contact" onClick={this.UrlA.bind(this, "contact")}>Contact</Link></li>
                                        </ul>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Nav;