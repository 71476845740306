import React, {Component} from 'react';

import "../../Css_List/App5_Global_css/Branch.css"


import ditu from '../../images/img5_Global/ditu.png'

export default class Branch extends Component {
    render() {
        return (
            <div id="Branch" >
                <div class="JiShuFW_box">
                    <div class="JiShuFW_top">


                        <h3 class="JiShuFW_h3" style={{"text-transform":"uppercase"}}>Global Division</h3>
                        <p>
                            Haidi Energy was established in Shandong and has a global layout.<br/>
                            Create sustainable future values and work to promote new energy development processes to serve the community.
                        </p>
                    </div>
                </div>
                <div className="ditu lazy_bg" data-original={ditu}>
                    <span className="U_S_A" >USA</span>
                    <span className="LDMZ" >Latin America</span>
                    <span className="Spain">Spain</span>
                    <span className="Denmark">Denmark</span>
                    <span className="Slovenia">Slovenia</span>
                    <span className="Turkey">Turkey</span>
                    <span className="India">India</span>
                    <span className="Shenzhen">Shenzhen</span>
                    <span className="Shandong">Headquarter</span>
                    <span className="Philippines">Philippines</span>
                    <span className="Korea">Korea</span>
                    <span className="Japan">Japan</span>
                    <p style={{"text-transform":"uppercase"}}>Branch contact information</p>
                </div>
                <div className="Branch_list">

                    <ul>
                        {/*总部*/}
                        <li>
                            <h3>HAIDI(Headquarter)
                            </h3>
                            <div>
                                <p className="w_100">No.6,Yongfu Road, Xuecheng District, Zaozhuang, Shandong Province, China</p>
                                <p>Tel: +86 632 4423 888 </p>
                                <p>Mobile：+86 13560791942</p>
                                <p className="w_100">Fax: +86 632 4423 885</p>
                                <p className="w_100">E-mail: haidi@haidienergy.com</p>
                            </div>
                        </li>
                        {/*深圳*/}
                        <li>
                            <h3>HAIDI (Shenzhen)</h3>
                            <div>
                                <p className="w_100">806, Taibang Technology Bldg, Hi-tech Park, Nanshan, Shenzhen, China</p>
                                <p>Tel: +86 755 8696 6894, 8652 0948</p>
                                <p className="w_100">Fax: +86 755 8652 7648</p>
                                <p className="w_100">E-mail: haidi@haidienergy.com</p>
                            </div>
                        </li>
                        {/*美国*/}
                        <li>
                            <h3>HAIDI (USA)</h3>
                            <div>
                                <p className="w_100">45016 Carla Ct., Lake Elsinore, CA92532, USA</p>
                                <p>Tel: +1 949 735 6090</p>
                                <p>Mobile: +1 949 295 0021</p>
                                <p className="w_100">Email: willam.xie@haidienergy.com</p>
                            </div>
                        </li>
                        {/*丹麦*/}
                        <li>
                            <h3>HAIDI (Denmark)</h3>
                            <div>
                                <p className="w_100">Gjellerupvej 89A, 8230 Aarhus, Denmark</p>
                                <p>Tel: +45 8861 8388</p>
                                <p>Mobile: +45 2390 9281</p>
                                <p className="w_100">E-mail: wstech@wstech.dk</p>
                            </div>
                        </li>
                        {/*斯洛文尼亚*/}
                        <li>
                            <h3>HAIDI (Slovenia)</h3>
                            <div>
                                <p className="w_100">Polena 6, SI-2392 Mezica, Slovenia</p>
                                <p>Tel: +386(0) 2870 2300 </p>
                                <p>Mobile: +386 41 293 478</p>
                                <p className="w_100">E-mail: tab@haidienergy.com</p>
                            </div>
                        </li>
                        {/*西班牙*/}
                        <li>
                            <h3>HAIDI (Spain)</h3>
                            <div>
                                <p className="w_100">GMiguel Angel 120, Barcelona, Spain</p>
                                <p>Tel: +34 66046 7885</p>
                                <p className="w_100">E-mail: joseramon@haidienergy.com</p>
                            </div>
                        </li>
                        {/*日本*/}
                        <li>
                            <h3>HAIDI(Japan)</h3>
                            <div>
                                <p className="w_100">1-93-1 Wakamiyaji Ginan-cho Hashima-gun Gifu-City, Japan</p>
                                <p>Tel: +81 (0)58 248 0736 </p>
                                <p>Fax: +81(0)58 248 0734</p>
                                <p className="w_100">Cell: +81 (0)80 4899 2875</p>
                                <p className="w_100">E-mail: japan@haidienergy.com</p>
                            </div>
                        </li>
                        {/*印度*/}
                        <li>
                            <h3>HAIDI (India)</h3>
                            <div>
                                <p className="w_100">D.No.8-2-616, Plot No.4, Road No.11, Banjara Hills, Hyderabad-34, T.S. India</p>
                                <p>Tel: +91-40-66167177, 23305581/82</p>
                                <p>Mobile: +91-96401 05435</p>
                                <p className="w_100">Fax: +91-8418 301652</p>
                                <p className="w_100">E-mail: hbl@haidienergy.com</p>
                            </div>
                        </li>
                        {/*拉丁美洲*/}
                        <li>
                            <h3>HAIDI (Latin America)</h3>
                            <div>
                                <p className="w_100">Paz Soldan 4960, C1427DSF, CABA, Argentina</p>
                                <p>Tel: +(54-11) 4514 6868 </p>
                                <p>Fax: (54-11) 4514-6866</p>
                                <p className="w_100">Email: baires@haidienergy.com</p>
                            </div>
                        </li>
                        {/*韩国*/}
                        <li>
                            <h3>HAIDI (Korea)</h3>
                            <div>
                                <p className="w_100">131 Dosin-ro, Youngdeungpo-gu, Seoul, Korea</p>
                                <p>Tel: +82 02 841 9775</p>
                                <p>Mobile +82 (0)10 5410 3172</p>
                                <p className="w_100">Email: korea@haidienergy.com</p>
                            </div>
                        </li>
                        {/*菲律宾*/}
                        <li>
                            <h3>HAIDI (Philippines)</h3>
                            <div>
                                <p className="w_100">4661 Makiling Street And Pres.Osmena Highway,</p>
                                <p className="w_100">Palanan, Makati City, 1235 Philippines</p>
                                <p>Tel: +632 834 5064 </p>
                                <p>Mobile +632 556 4481</p>
                                <p className="w_100">Email: junturla@haidienergy.com</p>
                            </div>
                        </li>
                        {/*土耳其*/}
                        <li>
                            <h3>HAIDI (Turkey)</h3>
                            <div>
                                <p className="w_100">Mecidiyekoy Mah. Sehit Ahmet Sk. Mecidiyekoy Is Merkezi, No:4/96</p>
                                <p className="w_100">Kat:8 Sisli – Istanbul /  TURKEY</p>
                                <p>Tel: +90 212 273 1666</p>
                                <p>Mobile: +90 533 437 1150</p>
                                <p className="w_100">Fax: +90 312 472 3275</p>
                                <p className="w_100">E-mail: d.akdemirbey@hdaenerji.com.tr</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="Branch_bot_div"></div>
            </div>
        )
    }
}