import React, {Component} from 'react';

import "../App1_css/Li.css"
import nav_2 from "../images/img/nav-2.png"

export default class Li extends Component {
    render() {
        return (
            <div id="Li">
                <div class="Limg lazy_bg" data-original={nav_2}></div>
                <div class="Lu">
                    <p>TURN-KEY SOLUTIONS FOR  </p>
                    <h3 style={{"text-transform":"uppercase"}}> CUSTOM MADE BATTERY PACKS</h3>
                </div>
                <ul className="Lu_ul">
                    <li>Golf Cart</li>
                    <li>EV </li>
                    <li>
                        Electric Yacht
                    </li>
                    <li>AGV </li>
                    <li>Forklift </li>
                </ul>
            </div>
        )
    }
}