import React, {Component} from 'react';

import "../../Css_List/App5_Global_css/JSFW.css"

import Q_01 from "../../images/img5_Global/Q_01.png"
import Q_02 from "../../images/img5_Global/02.png"
import Q_bg from "../../images/img5_Global/bg.png"
import sanJ from "../../images/img5_Global/sanJ.png"
import banner_2 from "../../images/img5_Global/banner2.png"

export default class JiShuFW extends Component {
    render() {
        return (
            <div id="JiShuFW" className="lazy_bg" data-original={Q_bg}>
                <div class="JiShuFW_box">
                    <div class="JiShuFW_top wow fadeInUp bg-yellow" data-wow-delay="1s" data-wow-duration="1s">

                        <h3 class="JiShuFW_h3">Technical Services</h3>
                        <p>
                            Provide customers with better quality assurance and service levels for customer satisfaction.<br/>
                            Haidi has built a service model of seven internal service modules + external cooperative service maintenance.
                        </p>
                    </div>
                    <div class="JiShuFW_bot">
                        <ul>
                            <li >
                                <img data-original={Q_01} alt="加载失败"/>
                                <p>
                                    Seven internal modules: market, scheduling, technology, accessories, repair, network development, claims, and a well-established integrated service system
                                </p>
                                <div className="sanJ lazy_bg" data-original={sanJ}></div>
                            </li>
                            <li>
                                <img data-original={Q_02} alt="加载失败"/>
                                <p>
                                    Through the cooperation of the industrial chain, the company has established a “self-built regional service marketing department” to coordinate service management.
                                </p>
                                <div className="sanJ lazy_bg" data-original={sanJ}></div>
                            </li>
                        </ul>
                    </div>

                </div>
                <div className="JiShuFW_ban lazy_bg" data-original={banner_2}>
                    <p style={{"text-transform":"uppercase"}}>
                        Pay attention to product quality, corporate responsibility
                    </p>
                </div>
            </div>
        )
    }
}