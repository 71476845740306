import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/DianDongGJ.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import ddgj_banner_1 from "../img4/Cp_DianDongGJ/ddgj-banner2.png";
import ddgj_1 from "../img4/Cp_DianDongGJ/ddgj-1.png";
import ddgj_2 from "../img4/Cp_DianDongGJ/ddgj-2.png";
import ddgj_3 from "../img4/Cp_DianDongGJ/ddgj-3.png";
import Request_Head from "../Js_List/Request_Head";
import Domain_Name from "../Js_List/Domain_Name";
import axios from "axios";




export default class DianDongGJ extends Component {

    constructor(props){
        super(props);
        var Request_Token = Request_Head;
        this.state={
            Token:Request_Token,/*请求头*/
            Makita:[],
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*Makita*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"3",
            type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Makita:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

    }

    render() {
        return (
            <div id="DianDongGJ" className="S4_box " style={{display: "none"}}>
                <div className="S4_Li_Curtain lazy_bg Cp_top_banner" data-original={ddgj_banner_1}>
                    <div>
                        <h3>POWER TOOL BATTERY</h3>
                        <p>
                            Provide high-reliability power supply for users for garden  <br/>tools,
                            medical equipment, professional power tools, etc.
                        </p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title">
                        <h3>
                            Power tool battery type
                        </h3>
                        <p>

                            Customizable power tool battery,<br/>
                            Can be widely used in new portable power tools

                        </p>
                    </div>
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ddgj_1}>
                                            </div>
                                            <div>
                                                <h3>Wide range of uses - lightweight design</h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>Minimize weight and ensure lightweight design to create a super-capacity battery</li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ddgj_2} alt=""/></div>
                                                    <div><img data-original={ddgj_3} alt=""/></div>


                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Innovative product provide powerful power</h3>
                                                        <p>
                                                            With the persistence of quality and the pursuit of high-end quality, we have developed a wide range of power tool batteries.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(g)</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Makita.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}