import React from 'react';

import '../App2_css/App2.css'
import FirstScreen from '../App2_js/FirstScreen'
import Jian from '../App2_js/Jian'
import Exp from '../App2_js/Experience'
import Modular3 from '../App2_js/Modular3'
import Select from '../App2_js/Select'
import Honor from '../App2_js/Honor'


class App2 extends React.Component{
    componentWillMount(){
        var url = window.location.href;
        var arrUrl = url.split("/");
        var oFUrl = arrUrl[arrUrl.length-1];
        var aFUrl = oFUrl.split("#");



    }
    render() {
        return (
            <div className="App2" >
                <section className="section-wrap">
                    <div className="section section-1">
                        <FirstScreen />
                    </div>
                    <div className="section section-2">
                        <Jian/>
                    </div>
                    <div className="section section-3">
                        <Exp/>
                    </div>
                    <div className="section section-4">
                        <Modular3/>
                    </div>
                    <div className="section section-5">
                        <Select/>
                    </div>
                    <div className="section section-6">
                        <Honor/>
                    </div>

                </section>
                <ul className="section-btn">
                    <li className="on"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>

            </div>
        );
    }
}

export default App2;
