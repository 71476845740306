import React,{Component} from 'react';

import {Carousel} from 'react-bootstrap';
import '../App1_css/des.css';

import logo1 from '../images/img/logo1.png';

import serch from '../images/img/serch.png';


export default class Logo extends Component{


    render (){
        return (
            <div >
                <img class="logo1 lazy"  data-original={logo1}  alt=""/>
                <div class="sou ">
                    <a class="serch"  href="http://en.haidienergy.com" />
                    <form class="form">
                        <input type="text" name="keyword" placeholder="请输入关键字"/>
                    </form>
                    <span> | </span>
                    <a href="https://zh.haidienergy.com">CN</a>
                </div>
            </div>

        );
    }
}
