import React, {Component} from 'react';

import "../App3_css/Banner.css"

import "../App4_Css/Cp_banner.css"

import banner1 from '../img4/banner1.jpg'
import Logo from "../App1_js/logo";

export default class Cp_banner extends Component {
    render() {
        return (
            <div id="Cp_banner" class="lazy_bg" data-original={banner1} style={{"background-attachment": "fixed"}}>
                <Logo/>
                <div class="Banner_title  Cp_banner_title"  data-scroll-reveal="enter bottom over 1s and move 200px after">
                    <h3>YOUR TRUSTED POWER OF CHOICE</h3>
                    <div>
                        <p style={{"font-size":"2.5rem",margin:"0","text-align":"center"}}>Advanced Lithium Battery Solutions</p>
                    </div>
                </div>
            </div>
        )
    }
}