import React, {Component} from 'react';

import "../App1_css/xing.css"

import Xing_bg from '../images/img/nav-4.png'

export default class Xing extends Component {
    render() {
        return (
            <div class="lazy lazy_bg" id="xing" data-original={Xing_bg}>
                <div class="hd" >
                    <h3 style={{"text-transform":"uppercase","color":"rgba(227,38,57,1)"}}>HAIDI LITHIUM CELL</h3>
                    <p style={{color:"rgba(87,87,87,1)"}}>
                        Haidi Lithium Iron Phosphate (LFP) and Nickel Manganese Cobalt (NMC) technologies offer high powered cell performance compatible with virtually any application to deliver more power and signicifcantly increased cycle life.
                    </p>
                </div>
            </div>
        )
    }
}