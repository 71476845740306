import React from 'react';

import '../Css_List/App5_Global_css/App5.css'

import Banner from "../Js_List/App5_Global_js/banner"
import JiShuFW from "../Js_List/App5_Global_js/JiShuFW"
import Branch from "../Js_List/App5_Global_js/Branch"
import Footer from "../App1_js/footer";

class App6_Global extends React.Component{
    render() {
        return (
            <div className="App5">
                <Banner/>
                <JiShuFW/>
                <Branch/>
                <Footer/>
            </div>
        );
    }
}

export default App6_Global;