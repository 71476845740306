import React, {Component} from 'react';

import '../App2_css/Select.css'

import bg5 from '../images/img2/bg5.png'
import zu12 from '../images/img2/zu12.png'

export default class Select extends Component {
    render() {
        return (
            <div id="Select">
                <div class="A2_box">
                    <div class="A2_top Select_top lazy_bg" data-original={bg5}>
                        <div class="sel_content">
                            <h3>Your Trusted Power Of Choice</h3>
                            <p>
                                After years of success in the global market, our R&D team is dedicated to continual product development to
                                improve the performance reliability of our product range. We have an extensive range of lithium batteries for
                                different kinds of applications.
                            </p>
                            <ul>
                                <li>
                                    <p>Battery Modules</p>
                                    <p>Sealed Lead Acid Replacement</p>
                                </li>
                                <li>
                                    <p>Energy Storage System </p>
                                    <p>Military Equipment</p>
                                </li>
                                <li>
                                    <p>Marine & Electric Boat</p>
                                    <p>Electric Vehicles</p>
                                </li>
                                <li>
                                    <p>Telecommunication</p>
                                    <p>Energy Storage Systems</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="A2_bot Select_bot lazy_bg" data-original={zu12}>
                        <ul>
                            <li>
                                <p>Industrial Application Expertise
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}