import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/DianXin.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import dx_banner2 from "../img4/Cp_dianxin/dx-banner2.png";
import sanyuan_1 from "../img4/Cp_dianxin/sanyuan-1.png";
import sanyuan_2 from "../img4/Cp_dianxin/sanyuna-2.png";
import sanyuan_3 from "../img4/Cp_dianxin/sanyuan-3.png";
import linsuan_1 from "../img4/Cp_dianxin/linsuan-1.png";
import linsuan_2 from "../img4/Cp_dianxin/linsuan-2.png";
import linsuan_3 from "../img4/Cp_dianxin/linsuan-3.png";
import Request_Head from "../Js_List/Request_Head";
import Domain_Name from "../Js_List/Domain_Name";
import axios from "axios";


export default class DianXin extends Component {

    constructor(props){
        super(props);

        /*请求头*/
        var Request_Token = Request_Head;

        this.state={
            Token:Request_Token,/*请求头*/
            sanY:[],
            liSuan:[],
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*三元材料*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"1",
            type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    sanY:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*磷酸铁锂材料*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"1",
            type:'2',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    liSuan:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });
    }


    render() {
        return (
            <div id="DianXin" className="S4_box " style={{display: "block"}}>
                <div className=" S4_Li_Curtain lazy_bg Cp_top_banner" data-original={dx_banner2}>
                    <div >
                        <h3 style={{"text-transform":"uppercase"}}>LITHIUM BATTERY CELL</h3>
                        <p>
                            Carries the most important<br/>
                            core part of the battery.
                        </p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title" >
                        <h3 >
                            Haidi Energy
                        </h3>
                        <p >

                            With two leading battery products, automated production equipment,<br/>
                            High-precision module production equipment, grasp every production link to ensure product safety and reliability.

                        </p>
                    </div>
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className=" Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className=" Cp_Select_modular_left lazy_bg" data-original={sanyuan_1}>
                                            </div>
                                            <div>
                                                <h3>Ternary material</h3>
                                                <ul className="Cp_Select_w100">
                                                    <li>Long cycle life
                                                    </li>
                                                    <li>Superior safety</li>
                                                    <li>Smaller size</li>
                                                    <li>Lighter Weight</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div>
                                                        <img data-original={sanyuan_2} alt=""/>
                                                    </div>
                                                    <div>
                                                        <img data-original={sanyuan_3} alt=""/>
                                                    </div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">

                                                    <div>
                                                        <h3>Ternary product types and advantages</h3>
                                                        <p>
                                                            The ternary material as a core material greatly enhances the energy density of the product up to 240Wh/kg.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                    <tr>
                                        <td rowspan={2}>Part No</td>
                                        <td rowspan={2}>
                                            Nominal<br/>
                                            Voltage
                                        </td>
                                        <td rowspan={2}>
                                            Rated<br/>
                                            Capacity
                                        </td>
                                        <td rowspan={2}>Internal <br /> Resistance</td>
                                        <td rowspan={2}>
                                            Discharge<br/>
                                            Rate
                                        </td>
                                        <td colspan={2}>Dimension(mm)</td>
                                        <td rowspan={2}>Weight(g)</td>
                                    </tr>
                                    <tr>
                                        <td>Diameter</td>
                                        <td>Height</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.sanY.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.internal_resistance}</td>
                                            <td>{data.discharge_rate}</td>
                                            <td>{data.diameter}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={linsuan_1}>
                                            </div>
                                            <div>
                                                <h3>Lithium iron phosphate material</h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>Low self discharge rate	</li>
                                                    <li>Wide working temperature</li>
                                                    <li>Cpacity Increased Flexibility</li>
                                                    <li>High charge & dischage current</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={linsuan_2} alt=""/></div>
                                                    <div><img data-original={linsuan_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>
                                                            Product type and advantage
                                                        </h3>
                                                        <p>
                                                            Lithium iron phosphate material has extremely stable performance as a core material
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Internal <br/> Resistance</td>
                                    <td rowSpan={2}>
                                        Discharge<br/>
                                        Rate
                                    </td>
                                    <td colSpan={2}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(g)</td>
                                </tr>
                                <tr>
                                    <td>Diameter</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                {this.state.liSuan.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.internal_resistance}</td>
                                            <td>{data.discharge_rate}</td>
                                            <td>{data.diameter}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}