import React, {Component} from 'react';

import "../App3_css/Section_1.css"

import yan1 from '../images/img3/01.jpg'
import yan1_1 from '../images/img3/01(1).png'
import yan2 from '../images/img3/02.jpg'
import yan2_1 from '../images/img3/02(1).png'
import yan3 from '../images/img3/03.jpg'
import yan3_1 from '../images/img3/03(1).png'
import yan4 from '../images/img3/04.jpg'
import yan4_1 from '../images/img3/04(1).png'

export default class Section_1 extends Component {
    render() {
        return (
            <div id="Section_1">
                <div class="S1_box">
                    <div class="S1_top" data-scroll-reveal="enter bottom over 1s and move 200px after 0.5s">
                        <h3 style={{"text-transform":"uppercase"}}>RESEARCH & DEVELOPMENT</h3>
                        <p>
                            At Haidi, we invest heavily in R&D and we have the most experienced and best engineers and technicians working for us to develop cutting–edge lithium battery production process and  turn-key solutions for custom made battery packs.
                        </p>
                    </div>
                    <div class="S1_bot">
                        <ul>
                            <li class="" data-wow-delay="0.5s" >
                                <div class="S1_li_div">
                                    <div class="S1_li_div_title"  >
                                        <img data-original={yan1_1} alt="" />
                                        <p>Engineer</p>
                                    </div>
                                    <div class="S1_li_div_content" >
                                        <div class="S1_li_div_img">
                                            <img data-original={yan1} />
                                        </div>
                                        <p >
                                            We have more than  90 experienced R&D Engineers.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="" data-wow-delay="1s">
                                <div className="S1_li_div">
                                    <div className="S1_li_div_title">
                                        <img data-original={yan2_1} alt=""/>
                                        <p>Investment</p>
                                    </div>
                                    <div className="S1_li_div_content">
                                        <div className="S1_li_div_img">
                                            <img data-original={yan2}/>
                                        </div>
                                        <p>
                                            20 % of our annual profits goes to R&D
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="" data-wow-delay="0.5s" data-wow-delay="1s">
                                <div className="S1_li_div">
                                    <div className="S1_li_div_title">
                                        <img data-original={yan3_1} alt=""/>
                                        <p>Patents</p>
                                    </div>
                                    <div className="S1_li_div_content">
                                        <div className="S1_li_div_img">
                                            <img data-original={yan3}/>
                                        </div>
                                        <p>
                                            So far we have achieved 120 patented innovations for cell and battery pack solutions.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class=""  data-wow-delay="1s" data-wow-duration="0.5s">
                                <div className="S1_li_div">
                                    <div className="S1_li_div_title">
                                        <img data-original={yan4_1} alt=""/>
                                        <p>Collaborations</p>
                                    </div>
                                    <div className="S1_li_div_content">
                                        <div className="S1_li_div_img">
                                            <img data-original={yan4}/>
                                        </div>
                                        <p>
                                            Cooperating with well-known universities &Labs in China and UK
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}