import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/QianS.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import qstd_banner2 from "../img4/Cp_QianS/qstd-banner2.png";
import qstd_1 from "../img4/Cp_QianS/qstd-1.png";
import qstd_2 from "../img4/Cp_QianS/qstd-2.png";
import qstd_3 from "../img4/Cp_QianS/qstd-3.png";

import mtc_1 from "../img4/Cp_QianS/mtc-1.png";
import mtc_2 from "../img4/Cp_QianS/mtc-2.png";
import mtc_3 from "../img4/Cp_QianS/mtc--3.png";

import qc_1 from "../img4/Cp_QianS/qc-1.png";
import qc_2 from "../img4/Cp_QianS/qc-2.png";
import qc_3 from "../img4/Cp_QianS/qc-3.png";

import yjzm_1 from "../img4/Cp_QianS/yjzm-1.png";
import yjzm_2 from "../img4/Cp_QianS/yjzm-2.png";
import yjzm_3 from "../img4/Cp_QianS/yjzm-3.png";
import axios from "axios";
import Domain_Name from "../Js_List/Domain_Name";
import Request_Head from "../Js_List/Request_Head";




export default class QianS extends Component {
    constructor(props){
        super(props);
        var Request_Token = Request_Head;
        this.state={
            Token:Request_Token,/*请求头*/
            cycle:[],/*12V深循环电池*/
            Motorcycle:[],/*摩托车启动电池*/
            Car:[],/*汽车启动电池*/
            Emergency:[],/*应急灯电池*/
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*12V深循环电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"4",
            type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    cycle:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);

                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*摩托车启动电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"4",
            type:'2',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Motorcycle:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*汽车启动电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"4",
            type:'3',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Car:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*应急灯电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"4",
            type:'4',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Emergency:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });
    }

    render() {
        return (
            <div id="QianS" className="S4_box " style={{display: "none"}}>
                <div className="S4_Li_Curtain lazy_bg Cp_top_banner" data-original={qstd_banner2}>
                    <div>
                        <h3 style={{"text-transform":"uppercase"}}>Lead acid replacement</h3>
                        <p>
                            Completely replace traditional sealed<br/>
                            lead batteries, more practical and reliable
                        </p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title">
                        <h3>
                            SEALED LEAD ACID REPLACEMENT LIFEPO4 BATTERY
                        </h3>
                        <p>
                            Haidi Energy's LiFePO4 lead acid replacement<br/>
                            The range is a direct "plug-in" range to replace the traditional sealed lead-acid battery
                        </p>
                    </div>
                    {/*12V*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={qstd_1}>
                                            </div>
                                            <div>
                                                <h3>6.4V4.5AH~12.8V300AH</h3>
                                                <ul>
                                                    <li>
                                                        Based on strong technology development capabilities and advanced manufacturing equipment
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={qstd_2} alt=""/> </div>
                                                    <div><img data-original={qstd_3} alt=""/> </div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Designed for vehicle start and stop</h3>
                                                        <p>
                                                            Using leading battery technology, it can receive a large amount of braking energy to recover electricity and allow frequent discharges in a short time.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                    <tr>
                                        <td rowSpan={2}>Part No</td>
                                        <td rowSpan={2}>
                                            Nominal<br/>
                                            Voltage
                                        </td>
                                        <td rowSpan={2}>
                                            Rated<br/>
                                            Capacity
                                        </td>
                                        <td rowSpan={2}>Energy(Wh)</td>
                                        <td colSpan={3}>Dimension(mm)</td>
                                        <td rowSpan={2}>Weight(kg)</td>
                                        <td rowSpan={2}>Terminal</td>
                                    </tr>
                                    <tr>
                                        <td>Length</td>
                                        <td>Width</td>
                                        <td>Height</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.cycle.map((data,i)=> {
                                        return(
                                            <tr key={i}>
                                                <td>{data.part_no}</td>
                                                <td>{data.nominal_voltage}</td>
                                                <td>{data.typical_capacity}</td>
                                                <td>{data.total_energy}</td>
                                                <td>{data.length}</td>
                                                <td>{data.width}</td>
                                                <td>{data.height}</td>
                                                <td>{data.weight}</td>
                                                <td>{data.terminal}</td>
                                            </tr>
                                        )
                                    }
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*摩托*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={mtc_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    Motorcycle Starting Battery
                                                </h3>
                                                <ul>
                                                    <li>
                                                        The lead-acid replacement battery has an instantaneous discharge current with excellent starting power
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div>
                                                        <img data-original={mtc_2} alt=""/>
                                                    </div>
                                                    <div><img data-original={mtc_3} alt=""/></div>                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Maximize starting performance</h3>
                                                        <p>
                                                            Designed for motorcycle start-up, higher start-up performance, smaller size, and more durable starter battery.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Energy(Wh)</td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                    <td rowSpan={2}>Terminal</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Motorcycle.map((data,i)=> {
                                        return(
                                            <tr key={i}>
                                                <td>{data.part_no}</td>
                                                <td>{data.nominal_voltage}</td>
                                                <td>{data.typical_capacity}</td>
                                                <td>{data.total_energy}</td>
                                                <td>{data.length}</td>
                                                <td>{data.width}</td>
                                                <td>{data.height}</td>
                                                <td>{data.weight}</td>
                                                <td>{data.terminal}</td>
                                            </tr>
                                        )
                                    }
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*汽车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={qc_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    Car Starting Battery
                                                </h3>
                                                <ul>
                                                    <li>
                                                        Excellent start-up and strong environmental adaptability, light weight and high discharge rate
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={qc_2} alt=""/></div>
                                                    <div><img data-original={qc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>start-up at low thmperature</h3>
                                                        <p>
                                                            wide operating teperrature from -30
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowSpan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Energy(Wh)</td>
                                    <td colSpan={3}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                    <td rowSpan={2}>Terminal</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Car.map((data,i)=> {
                                        return(
                                            <tr key={i}>
                                                <td>{data.part_no}</td>
                                                <td>{data.nominal_voltage}</td>
                                                <td>{data.typical_capacity}</td>
                                                <td>{data.total_energy}</td>
                                                <td>{data.length}</td>
                                                <td>{data.width}</td>
                                                <td>{data.height}</td>
                                                <td>{data.weight}</td>
                                                <td>{data.terminal}</td>
                                            </tr>
                                        )
                                    }
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*照明*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={yjzm_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    Lighting Battery
                                                </h3>
                                                <ul>
                                                    <li>Strict control and precision testing, only to bring light and reduce energy loss and waste</li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={yjzm_2} alt=""/> </div>
                                                    <div><img data-original={yjzm_3} alt=""/> </div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Low power consumption</h3>
                                                        <p>
                                                            Professional R&D and production provide safer and more reliable new energy street lights to create a clean energy ecosystem.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Part No</td>
                                    <td rowSpan={2}>
                                        Rechargeable<br/>Batteries
                                    </td>
                                    <td rowSpan={2}>Configure</td>
                                </tr>

                                </thead>
                                <tbody>
                                {this.state.Emergency.map((data,i)=> {
                                        return(
                                            <tr key={i}>
                                                <td>{data.part_no}</td>
                                                <td>{data.battery_cell}</td>
                                                <td>{data.configuration}</td>
                                            </tr>
                                        )
                                    }
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}