import React, {Component} from 'react';

import "../App2_css/Exp.css"

import bg2 from "../images/img2/bg2.png"
import bg3 from "../images/img2/bg3.jpg"
import zuo_btn_hui from "../images/img2/zuo-btn-hui.png"
import zuo_btn from "../images/img2/zuo-btn.png"
import you_btn_bai from "../images/img2/you-btn-bai.png"
import you_btn from "../images/img2/you-btn.png"

export default class Exp extends Component {
    constructor(props){
        super(props);
        this.state ={
            "marginLeft" : "-100%",
            "off" : false,
            "num" : 0
        }
    }

    componentDidMount(){
        var OExp = document.getElementById('Exp');
        var oTurn = OExp.getElementsByClassName('Exp_turn_content')[0];
        var oLi = oTurn.getElementsByTagName('li');
        this.setState({'num':oLi.length})
        // console.log(this.state.num)
    }
    handlePrev (){
        if(this.state.off){
            return ;
        }
        var OExp = document.getElementById('Exp');
        var oTurn = OExp.getElementsByClassName('Exp_turn_content')[0];
        var Left  = parseInt(this.state.marginLeft) - 25;
        if(Math.abs(Left)<125){
            this.setState({"marginLeft":Left+"%","off":true});
        }
        setTimeout(()=>{
            this.setState({"off":false});
            // console.log(this.state.off)
        },1200)
    }
    handleNext (){
        if(this.state.off){
            return ;
        }
        var OExp = document.getElementById('Exp');
        var oTurn = OExp.getElementsByClassName('Exp_turn_content')[0];
        var Left  = parseInt(this.state.marginLeft) + 25;
        if(parseInt(this.state.marginLeft)<0){
            this.setState({"marginLeft":Left+"%","off":true})
        }
        setTimeout(()=>{
            this.setState({"off":false});
        },1200)
    }
    render() {
        return (
            <div id="Exp">
                <div class="A2_box Exp_box">
                    <div class="A2_top Exp_top lazy_bg" data-original={bg2}>
                        <div class="Exp_t_content">
                            <h3 className="Exp_t_title">Company Milestones</h3>
                            <div class="Exp_turn">
                                <div class="Exp_turn_content">
                                    <ul style={{"marginLeft":this.state.marginLeft}}>
                                        <li>
                                            {/*2011*/}
                                            <div>
                                                <h2>2011</h2>
                                                <span></span>
                                                <div>
                                                    <h3>December</h3>
                                                    <p>
                                                        Haidi Energy Lithium Power Technology Co., Ltd officially established
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2012*/}
                                            <div>
                                                <h2>2012</h2>
                                                <span></span>
                                                <div>
                                                    <h3>January</h3>
                                                    <p>
                                                        Sign strategic cooperation agreement with Shandong zhi guang dian research
                                                    </p>
                                                </div>
                                                <div>
                                                    <h3>August</h3>
                                                    <p>
                                                        Haidi Smart Grid Project approved by the Government
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2013*/}
                                            <div>
                                                <h2>2013</h2>
                                                <span></span>
                                                <div>
                                                    <h3>May</h3>
                                                    <p>
                                                        Lithium hexachlorophosphate project included in the catalogue of national industrial strength basic demonstration projects
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2014*/}
                                            <div>
                                                <h2>2014</h2>
                                                <span></span>
                                                <div>
                                                    <h3>August</h3>
                                                    <p>
                                                        Haidi combined with Hebei University of Technology to set up "Lithium Power New Energy Technology Transformation Center"
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2015*/}
                                            <div>
                                                <h2>2015</h2>
                                                <span></span>
                                                <div>
                                                    <h3>July</h3>
                                                    <p>
                                                        Passed TS16847 qualification
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2016*/}
                                            <div>
                                                <h2>2016</h2>
                                                <span></span>
                                                <div>
                                                    <h3>February</h3>
                                                    <p>
                                                        Haidi is supporting the battery of Zhongtaiyun 100 new energy vehicle through the national catalogue
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2017*/}
                                            <div>
                                                <h2>2017</h2>
                                                <span></span>
                                                <div>
                                                    <h3>September</h3>
                                                    <p>
                                                        Setting up a UK liaison station
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2018*/}
                                            <div>
                                                <h2>2018</h2>
                                                <span></span>
                                                <div>
                                                    <h3>December</h3>
                                                    <p>
                                                        We are moving forward.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="Exp_turn_arrows" >
                                    <div class="E_prev">
                                        <a href="javascript:;" onClick={this.handleNext.bind(this)}>
                                            <img className="arrows" data-original={zuo_btn_hui} alt=""/>
                                            <img className="arrows_b" data-original={zuo_btn} alt=""/>
                                        </a>
                                    </div>
                                    <div className="E_next">
                                        <a href="javascript:;" onClick={this.handlePrev.bind(this)}>
                                            <img className="arrows" data-original={you_btn} alt=""/>
                                            <img className="arrows_b" data-original={you_btn_bai} alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="A2_bot Exp_bot lazy_bg" data-original={bg3}>
                        <h3>Dedicated to service for over  15 years.</h3>
                        <p>
                            Haidi Energy has upheld its mission to deliver exceptional customer service and innovative solutions to our customers when and where they need us.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}