import React,{Component} from 'react';

import {Carousel} from 'react-bootstrap';
import '../App1_css/des.css';

import banner1 from '../images/img/banner1.png'
import banner2 from '../images/img/banner2_1.png'
import banner3 from '../images/img/banner-3.png'

import shubiao_icon from '../images/img/shubiao-icon.png';

import Logo from "./logo";

export default class Section extends Component{
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            direction: null
        };
    }
    getInitialState() {
        return {
            index: 0,
            direction: null
        };
    }

    handleSelect(selectedIndex, e) {
        // alert('selected=' + selectedIndex + ', direction=' + e.direction);
        this.setState({
            index: selectedIndex,
            direction: e.direction
        });
    }

    render (){
        return (
                <div id='scrollBar'>
                    <Logo/>
                    <Carousel pauseOnHover={false} interval="3000" activeIndex={this.state.index} direction={this.state.direction} onSelect={this.handleSelect.bind(this)}>
                        <Carousel.Item>
                            <div class="banner1 lazy_bg" data-original={banner1}></div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div  class="banner2 lazy_bg" data-original={banner2}></div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div  class="banner3 lazy_bg" data-original={banner3}></div>
                        </Carousel.Item>
                    </Carousel>
                    <div class="mouses">
                        <a class="lazy lazy_bg" data-original={shubiao_icon} href="javascript:;"></a>
                    </div>
                    <div className='tag1'>
                        <h3>Haidi Energy</h3>
                        <p>Global Leading LiFePO4 Battery Manufacturer</p>
                    </div>
                </div>

        );
    }
}
