import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/ChuNengXT.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import cnxt_banner2 from "../img4/Cp_ChuNengXT/cnxt-banner2.png";

import cnx_1 from "../img4/Cp_ChuNengXT/cnx-1.png";
import cnx_2 from "../img4/Cp_ChuNengXT/cnx-2.png";
import cnx_3 from "../img4/Cp_ChuNengXT/cnx-3.png";


import ups_1 from "../img4/Cp_ChuNengXT/ups-1.png";
import ups_2 from "../img4/Cp_ChuNengXT/ups-2.png";
import ups_3 from "../img4/Cp_ChuNengXT/ups-3.png";

import tx_1 from "../img4/Cp_ChuNengXT/tx-1.png";
import tx_2 from "../img4/Cp_ChuNengXT/tx-2.png";
import tx_3 from "../img4/Cp_ChuNengXT/tx-3.png";

import tynxn_1 from "../img4/Cp_ChuNengXT/tynxn-1.png";
import tynxn_2 from "../img4/Cp_ChuNengXT/tyncn-2.png";
import tynxn_3 from "../img4/Cp_ChuNengXT/tyncn-3.png";
import axios from "axios";
import Domain_Name from "../Js_List/Domain_Name";
import Request_Head from "../Js_List/Request_Head";

export default class ChuNengXT extends Component {

    constructor(props){
        super(props);
        var Request_Token = Request_Head;
        this.state={
            Token:Request_Token,/*请求头*/
            Powerbox:[],/*壁挂式储能箱*/
            UPS:[],/*UPS电池*/
            Telecom_Backup:[],/*通讯领域备用电源*/
            Solar_Energy:[],/*太阳能储能系统*/
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*壁挂式储能箱*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"6",
            type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Powerbox:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*UPS电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"6",
            type:'2',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    UPS:response.data.data.data,
                    isLoaded:true
                });
            })

        /*通讯领域备用电源*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"6",
            type:'3',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Telecom_Backup:response.data.data.data,
                    isLoaded:true
                });
            })

        /*太阳能储能系统*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"6",
            type:'4',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Solar_Energy:response.data.data.data,
                    isLoaded:true
                });

            })
    }

    render() {
        return (
            <div id="ChuNengXT" className="S4_box " style={{display: "none"}}>
                <div className="S4_Li_Curtain lazy_bg Cp_top_banner" data-original={cnxt_banner2}>
                    <div>
                        <h3 style={{"text-transform":"uppercase"}}>"Core" promotes ecology</h3>
                        <p>
                            Product applications are extensive,<br/>
                            Achieve efficient use of new energy

                        </p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title">
                        <h3>
                            ENERGY STORAGE SYSTEM
                        </h3>
                        <p>
                            We can provide a wide range of lithium battery energy storage systems<br/>
                            From kWh residential use to multi-megawatt system utilities
                        </p>
                    </div>
                    {/*储能箱*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={cnx_1}>
                                            </div>
                                            <div className="bai">
                                                <h3>
                                                    Powerbox
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>
                                                        Modular provides 3.2V to 1000V system;<br/>
                                                        Can be extended from kWh to MWh
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={cnx_2} alt=""/></div>
                                                    <div><img data-original={cnx_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Intelligent household energy storage system</h3>
                                                        <p>
                                                            Energy storage boxes provide the perfect solution for optimizing the use of solar energy. Long service life, high temperature resistance, APP intelligent control...
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>Model No</td>
                                    <td rowspan={2}>Nominal<br/>Voltage</td>
                                    <td rowspan={2}>Rated <br/>Capacity</td>
                                    <td rowspan={2}>Dimension(mm)</td>
                                    <td rowspan={2}>Weight(kg)</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Powerbox.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}*{data.width}*{data.height}</td>

                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*数据中心UPS*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ups_1}>
                                            </div>
                                            <div >
                                                <h3>
                                                    Data Center UPS
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>
                                                        Prevent important equipment from being powered off, emergency backup power supply
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ups_2} alt=""/></div>
                                                    <div><img data-original={ups_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Data center customized solution</h3>
                                                        <p>
                                                            High-power series batteries meet the needs of high-power discharge equipment such as UPS and EPS; provide customers with stable and reliable backup energy protection.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Model No</td>
                                    <td rowSpan={2}>Nominal<br/>Voltage</td>
                                    <td rowSpan={2}>Rated <br/>Capacity</td>
                                    <td rowSpan={2}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.UPS.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}*{data.width}*{data.height}</td>

                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*通讯领域备用电源*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={tx_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    Telecom Backup
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>Wide temperature range</li>
                                                    <li>Light weight</li>
                                                    <li>small volume</li>
                                                    <li>high energy density</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={tx_2} alt=""/></div>
                                                    <div><img data-original={tx_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Communication emergency energy</h3>
                                                        <p>
                                                            To meet the stable power supply required by the communication base station, the maintenance cost of the communication base station can be reduced.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Model No</td>
                                    <td rowSpan={2}>Nominal<br/>Voltage</td>
                                    <td rowSpan={2}>Rated <br/>Capacity</td>
                                    <td rowSpan={2}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Telecom_Backup.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}*{data.width}*{data.height}</td>

                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*太阳能储能*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={tynxn_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    Solar Energy Storage System
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>
                                                        Seamlessly connected to fuel cells, solar and wind power<br/>
                                                        Can be averaged by ESS to improve power quality
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={tynxn_2} alt=""/></div>
                                                    <div><img data-original={tynxn_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>Powerful storage products</h3>
                                                        <p>

                                                            Provide stability and peak management for power, reduce peak load' Improve power quality, store energy in batteries for later use, or feed energy back to the grid.

                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowSpan={2}>Model No</td>
                                    <td rowSpan={2}>Nominal<br/>Voltage</td>
                                    <td rowSpan={2}>Rated <br/>Capacity</td>
                                    <td rowSpan={2}>Dimension(mm)</td>
                                    <td rowSpan={2}>Weight(kg)</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Solar_Energy.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}*{data.width}*{data.height}</td>

                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}