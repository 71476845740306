import React, {Component} from 'react';

import "../../Css_List/App6_XW_css/XW_activity_01.css"

import banner1 from '../../images/img6_XW/XW_01_banner1.png'
import XW_01_img_1 from '../../images/img6_XW/02/XW_02_01 (1).png'
import XW_01_img_2 from '../../images/img6_XW/02/XW_02_01 (2).png'
import XW_01_img_3 from '../../images/img6_XW/02/XW_02_01 (3).png'
import XW_01_img_4 from '../../images/img6_XW/02/XW_02_01 (4).png'
import XW_01_img_5 from '../../images/img6_XW/02/XW_02_01 (5).png'
import logo1 from "../../images/img/logo1.png";
import Footer from "../../App1_js/footer";


import Link_a from './Link_a'
import XW_select from "./XW_select";
import Banner_news from "./banner_news";

export default class XW_activity_03 extends Component {
    render() {
        return (
            <div>
                <Banner_news/>
                <XW_select/>
                <div className="XW_activity_01">

                    <div id="Section_4 " className="XW_select">

                        <div className="XW_activity_box">
                            <div>
                                <h1>US 2018 Solar Power International exhibition returned successfully</h1>
                                <p>Release Time：2018.09.27 &nbsp;&nbsp;&nbsp;   12：50</p>
                                <div className="XW_activity_content">
                                    <p>
                                        SolarPower International, the annual international solar energy exhibition, is held annually in the United States from September to October every year in San Diego, Anaheim, Los Angeles, etc. The 24th Intersolar exhibition in the US city from September 25th to 27th, 2018 Naheim is grandly held. Haidi New Energy, as a pioneer in the field of energy storage in China and a technology leader, was invited to participate in the event.
                                        SolarPower International is currently the largest solar professional trade fair in the United States and the world, and one of the most successful and influential international exhibitions in the industry. According to the official website data, the last four-day exhibition, with more than 750 companies from all over the world, and 20,000 professional visitors, is the largest solar energy event in North America.
                                    </p>
                                    <p>
                                        At the exhibition site, the Haidi booth was full of people, and the new and old customers who went to the booth for business negotiation were in a constant stream. Haidi took the core technology products of independent innovation and debuted, interpreting the “high efficiency, high reliability and high value”, from the world. Exhibitors from all over the world showed the ingenious achievements created by China and won the praise and affirmation of the on-site exhibitors.
                                    </p>
                                    <p>
                                        As far as solar energy and energy storage industries are concerned, the United States is a country with more common new energy sources and more mature and developed technologies in the world.
                                        At this exhibition, we met many domestic and foreign companies in Europe. Through this exhibition, the company actively sought
                                        Seeking new growth points in overseas markets, once again showing the strength of new energy products to global customers, and laying a solid foundation for the layout of the American market
                                        On the basis of the real foundation, Haidi New Energy has further stabilized the international market, improved its international reputation, and laid the foundation for the next high-end market.
                                        Set a solid foundation.
                                    </p>
                                    <p>
                                        Not forgetting the original heart, Haidi’s original intention is to provide the best service to help users achieve sustainable and economical power and energy management.
                                        Practice, whether it reduces user dependence on coal-fired power generation facilities, reduces carbon emissions, or accelerates the adoption of renewable energy and alternative fuels
                                        Material car.
                                    </p>
                                    <p>
                                        Haidi has a team of professional technical research and development engineers. The company is dedicated to the research and development of new energy products, from technology research and development, to
                                        The product itself, the overall solution to the commercialization of the product, do not forget the initial heart, quietly study, and finally win the market with strength, Haidi
                                        Always believe that technology-driven products and services, in the face of a unique potential market, speak with strength.
                                    </p>
                                    <p>
                                        About Haidi：<br/>
                                        Haidi New Energy is one of the leading lithium battery manufacturers and high-tech companies in China. The company specializes in lithium iron phosphate (LiFeP04).
                                        Research, development, manufacturing and sales of batteries and lithium-ion batteries. Our standard factory covers an area of about 200,000 square meters, mainly producing
                                        Cathode powder, battery and battery pack. Haidi has more than 750 employees and more than 90 experienced R&D engineers, R&D team
                                        Committed to continuous product development to improve the performance reliability of new energy product lines.
                                    </p>
                                    <img data-original={XW_01_img_1} alt=""/>
                                    <img data-original={XW_01_img_2} alt=""/>
                                    <img data-original={XW_01_img_3} alt=""/>
                                    <img data-original={XW_01_img_4} alt=""/>
                                    <img data-original={XW_01_img_5} alt=""/>
                                </div>
                                <div className="XW_activity_list">

                                    <p><span>Last one: </span><a href="javascript:;">European Exhibition</a></p>
                                    <p className="XW_activity_list_return">
                                        <Link_a path={"/news"} text={"Return list"}></Link_a>
                                    </p>
                                    <p><span>Next one: </span><a href="javascript:;">Innovation Technology 2019</a></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}