import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/MoZu.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import mozu_banner2 from "../img4/Cp_mozu/mozu-banner2.png";
import mozu_1 from "../img4/Cp_mozu/mozu-1.png";
import mozu_2 from "../img4/Cp_mozu/mozu-2.png";
import mozu_3 from "../img4/Cp_mozu/mozu-3.png";
import icon_1 from "../img4/Cp_mozu/icon-1.png";
import icon_2 from "../img4/Cp_mozu/icon-2.png";
import icon_3 from "../img4/Cp_mozu/icon-3.png";
import icon_4 from "../img4/Cp_mozu/icon-4.png";
import icon_5 from "../img4/Cp_mozu/icon-5.png";
import icon_6 from "../img4/Cp_mozu/icon-6.png";
import Request_Head from "../Js_List/Request_Head";
import axios from "axios";
import Domain_Name from "../Js_List/Domain_Name";



export default class MoZu extends Component {
    constructor(props){
        super(props);
        /*请求头*/
        var Request_Token = Request_Head;

        this.state={
            Token:Request_Token,/*请求头*/
            mozu_san:[],
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*三元锂模组*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"2",
            type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    mozu_san:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

    }

    render() {
        return (
            <div id="MoZu" className="S4_box " style={{display: "none"}}>
                <div className="S4_Li_Curtain lazy_bg Cp_top_banner" data-original={mozu_banner2}>
                    <div>
                        <h3 style={{"text-transform":"uppercase"}}>Customized module</h3>
                        <p>
                            Design integrated solutions for modules
                            and production according to different needs
                        </p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title">
                        <h3>
                            Module characteristics
                        </h3>
                        <p>
                            Automated production equipment, high-precision production equipment,<br/>
                            and grasp every production link to ensure product safety and reliability.
                        </p>
                    </div>
                    <div className="MoZu_li">
                        <ul>
                            <li>
                                <div>
                                    <img data-original={icon_1} alt=""/>
                                    <p>

                                        Industry-leading<br/>
                                        Cycle life

                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img data-original={icon_2} alt=""/>
                                    <p>

                                        Wide operating<br/>
                                        temperature range

                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img data-original={icon_3} alt=""/>
                                    <p>

                                        Flame Retardant<br/>
                                        Housing

                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img data-original={icon_4} alt=""/>
                                    <p>

                                        Ultra safety<br/>
                                        performance

                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img data-original={icon_5} alt=""/>
                                    <p>

                                        High charge and<br/>
                                        discharge capability

                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img data-original={icon_6} alt=""/>
                                    <p>

                                        Module design<br/>
                                        easy for installation

                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={mozu_1}>
                                            </div>
                                            <div>
                                                <h3>Customized module</h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>
                                                        Advanced welding technology, proven process, higher quality and reliability
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={mozu_2} alt=""/></div>
                                                    <div><img data-original={mozu_3} alt=""/></div>


                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>High-end customized module</h3>
                                                        <p>
                                                            Lithium battery module built-in 18650/26650 battery integrated platform, flexible design module, designed for high-power electric vehicles.
                                                        </p>
                                                        <div className="CP_click">Read More</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>Part No</td>
                                    <td rowspan={2}>
                                        Nominal<br/>
                                        Voltage
                                    </td>
                                    <td rowspan={2}>
                                        Rated<br/>
                                        Capacity
                                    </td>
                                    <td rowSpan={2}>Energy(Wh)</td>
                                    <td colspan={3}>Dimension(mm)</td>
                                    <td rowspan={2}>Weight(kg)</td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>Width</td>
                                    <td>Height</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.mozu_san.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.terminal}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}


                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}