import React, {Component} from 'react';

import '../App2_css/Jian.css'

import {Container,Row,Col} from 'react-bootstrap'

import logo from "../images/img2/logo2.png"
import bg1 from "../images/img2/bg1.jpg"

export default class Jian extends Component {
    render() {
        return (
            <div id="Jian">
                <div class="A2_box">
                    <div class="A2_top">

                        <div class="J_top_content">
                            <Container>
                                <Row>

                                    <Col>
                                        <div class="J_R">
                                            <p>
                                                Haidi Eneray is one of the leading lithium battery manufacturer and high-tech company in China.We are specializing in the research, development, manufacturing and sales of the lithium iron phosphate(LiFeP04) batteries and lithium ion(NMC) batteries. Our standard workshops cover about 200000 square meters for the cathode powder, battery cells and battery packs production.We have more than 750 employees and 90 experienced R&D Engineers.
                                                <p></p>
                                                After years of success in the global market, our R&D team is dedicated to continual product development to improve the performance reliability of our product range. We have an extensive range of lithium batteries for different kinds of applications.<br/>
                                                <p></p>
                                                Lightings<br/>
                                                Lead Acid Replacement<br/>
                                                Medical Equipment<br/>
                                                Marine & Electric Boat<br/>
                                                Telecommunication<br/>
                                                UPS, Backup Power<br/>
                                                Military Equipment<br/>
                                                Electric Vehicles<br/>
                                                Energy Storage Systems<br/>
                                                <p></p>
                                                Haidi Energy was certified with ISO9001, IS014001,TS 16949 and OHSAS18001 managemen systems. Our products have obtained UL, CE, TUV, IEC62133, CCA, KC, BIS, ROHS, UN38.3 MSDS certificates and also have the worldwide Product Liability Insurance.
                                                Our goal is to provide the best service in helping our customers to achieve sustainable and economically sensible power and energy management practices. Whether it is reducing our dependencies on coal-fired generation facilities, reducing carbon emissions or accelerating the adoption of renewable integration and alternative-fuel vehicles.
                                                Haidi Energy- A Leading LiFePO4 Batteries Manufacturer!
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <div className="A2_bot j_bot lazy_bg" data-original={bg1}>
                        <div class="j_b_content">
                            <h3 style={{"text-transform":"uppercase"}}>Dedicated to service for over  15 years. </h3>
                            <p style={{"text-align":"content"}}>
                                We value our relationships and continues to take the long-term view which has allowed the company to develop strong partnerships with our customers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}