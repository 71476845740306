import React from "react";

import "../App1_css/footer.css"

import logo from '../images/img/logo.png'
import yyzz from '../images/img/yyzz.jpg'
import yyzz_1 from '../images/img/yyzz_1.png'
import {Link} from "react-router-dom";

class Footer extends React.Component{
    constructor(){
        super();
        this.LinkClick = this.LinkClick.bind(this)
        this.UrlA = this.UrlA.bind(this)
    }
    LinkClick (section,i){
        setTimeout(function () {
            window.location.reload();
        },100)
    }
    UrlA(a,section){
        var url = window.location.href;
        var arrUrl = url.split("/");
        var oFUrl = arrUrl[arrUrl.length-1];
        var aFUrl = oFUrl.split("#");

        if(aFUrl!=a){
            this.LinkClick(section);
        }
    }
    render() {
        return(
            <div id="foot">
                <div className="footer">
                <div className="footer-top">
                    <div className="footer-logo">
                        <img className="lazy"   data-original={logo} alt="logo"/>
                    </div>
                    <div className="footer-desc">
                        <p>Haidi Energy- A Leading LiFePO4 Batteries Manufacturer</p>
                    </div>
                </div>
                <div className="footer-line"></div>
                <div className="footer-bottom">
                    <div className="footer-nav">
                        <div className="footer_menu">
                            <ul className="col-links">
                                <li><Link to="/" onClick={this.LinkClick.bind(this)}>Home</Link></li>
                            </ul>
                            <ul className="col-links">
                                <li><Link to="/about" onClick={this.LinkClick.bind(this)}>About Haidi</Link></li>
                                {/*<li><Link to="/a">关于海帝</Link></li>*/}
                                <li><Link to="/about"  onClick={this.UrlA.bind(this,"about","section","1")}>Introduction</Link></li>
                                <li><Link to="/about" onClick={this.UrlA.bind(this,"about")}>History</Link></li>
                                <li><Link to="/about" onClick={this.UrlA.bind(this,"about")}>Philosophy</Link></li>
                                <li><Link to="/about" onClick={this.UrlA.bind(this,"about")} >Service Area</Link></li>
                                <li><Link to="/about" onClick={this.UrlA.bind(this,"about")} >Honor</Link></li>
                            </ul>
                            <ul className="col-links">
                                <li><Link to="/research" onClick={this.LinkClick.bind(this)}>Technical </Link></li>
                                <li><Link to="/research" onClick={this.UrlA.bind(this,"research","Section_4")}>Advantage</Link></li>
                                <li><Link to="/research" onClick={this.UrlA.bind(this,"research","Section_4")}>Core Technology</Link></li>

                            </ul>
                            <ul className="col-links">
                                <li><Link to="/product" onClick={this.LinkClick.bind(this)}>Product</Link></li>
                                <li><Link to="/product" onClick={this.UrlA.bind(this,"product")}>Solutions</Link></li>
                                <li><Link to="/product" onClick={this.UrlA.bind(this,"product")}>Products</Link></li>
                            </ul>
                            <ul className="col-links">
                                <li><Link to="/global" onClick={this.LinkClick.bind(this)}>Global Service</Link></li>
                                <li><Link to="/global"
                                          onClick={this.UrlA.bind(this, "global", "Section_4")}>Service Concept</Link></li>
                                <li><Link to="/global"
                                          onClick={this.UrlA.bind(this, "global", "Section_4")}>Global Network</Link>
                                </li>
                                <li><Link to="/global" onClick={this.UrlA.bind(this, "global")}>Branch Contact</Link></li>
                            </ul>
                            <ul className="col-links">
                                <li><Link to="/news" onClick={this.LinkClick.bind(this)}>News </Link></li>
                                <li><Link to="/news" onClick={this.UrlA.bind(this, "xw")}>Company News</Link></li>
                                <li><Link to="/industry" onClick={this.UrlA.bind(this, "xw_zx")}>Industry News</Link></li>
                            </ul>
                            <ul className="col-links">
                                <li><Link to="/contact" onClick={this.LinkClick.bind(this)}>Contact us</Link></li>
                                <li><Link to="/contact" onClick={this.UrlA.bind(this, "contact")}>Recruitment</Link></li>
                                <li><Link to="/contact" onClick={this.UrlA.bind(this, "contact")}>Contact</Link></li>
                            </ul>
                        </div>
                        <div className="footer-hr"></div>
                        <div className="footer-address">
                            <p><span>Tel ：</span>+86 632 400-042-3885</p>
                            <p>+86 632-4423800 / 632-4423888</p>
                            <p>Fax：+86 632-4423885 / 632-4423500</p>
                            {/*<p> </p>*/}
                            <p>Mobile: +86 13560791942</p>
                            <p>E-mall : haidi@haidienergy.com</p>
                        </div>
                    </div>

                    <center className="footer_number">
                        鲁公网安备 37040302006012号   <a href="http://beian.miit.gov.cn/" rel="nofollow" target="_blank">鲁ICP备15007069号-1</a> 
                        <a target="_blank" className="yyzz" href={yyzz_1}>
                            <img className="lazy"   data-original={yyzz} alt="logo"/>
                        </a>
                    </center>
                </div>
            </div>
            </div>
        )
    }
}

export default Footer;