import React, {Component} from 'react';

import "../../Css_List/App6_XW_css/XW_select.css"

import {  Link } from "react-router-dom";

export default class XW_select extends Component {
    constructor(){
        super();
        this.UrlA = this.UrlA.bind(this)
    }
    UrlA(a,section){
        var url = window.location.href;
        var arrUrl = url.split("/");
        var oFUrl = arrUrl[arrUrl.length-1];
        var aFUrl = oFUrl.split("#");

        if(aFUrl!=a){
            setTimeout(function () {
                window.location.reload();
            },100)
        }

    }
    render() {
        return (
            <div id="Section_4" className="XW_select">
                <div className="S4_router Cp_Select XW_select_li">
                    <ul>
                        <li className="red">

                            <Link to="/news" onClick={this.UrlA.bind(this,"xw")}>Company news</Link>
                        </li>
                        <li  ><Link to="/industry" onClick={this.UrlA.bind(this,"xw_zx")}>Industry news</Link></li>
                    </ul>
                </div>

            </div>
        )
    }
}