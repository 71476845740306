import React, {Component} from 'react';

import "../../Css_List/App6_XW_css/XW_activity_01.css"

import banner1 from '../../images/img6_XW/XW_01_banner1.png'
import XW_01_img_1 from '../../images/img6_XW/HD/XW_01_01.png'
import XW_01_img_2 from '../../images/img6_XW/HD/XW_01_02.png'
import XW_01_img_3 from '../../images/img6_XW/HD/XW_01_03.png'
import XW_01_img_4 from '../../images/img6_XW/HD/XW_01_04.png'
import XW_01_img_5 from '../../images/img6_XW/HD/XW_01_05.png'
import logo1 from "../../images/img/logo1.png";
import Footer from "../../App1_js/footer";


import Link_a from './Link_a'

import XW_select from "./XW_select";
import Banner_news from "./banner_news";

export default class XW_activity_02 extends Component {
    render() {
        return (
            <div>
                <Banner_news/>
                <XW_select/>
                <div className="XW_activity_01">

                    <div id="Section_4 " className="XW_select">

                        <div className="XW_activity_box">
                            <div>
                                <h1>In Munich, Germany, Haidi's new energy solution is stunningly unveiled<br/>Intersolar Europe 2019</h1>
                                <p>Release Time：2019.05.20 &nbsp;&nbsp;&nbsp;   12：50</p>
                                <div className="XW_activity_content">
                                    <p>
                                        On May 17th, Germany, the largest and most influential professional exhibition fair in the solar and energy storage industry, the 26th Intersolar Europe 2019, was opened as scheduled. Haidi, a leading energy company, has exhibited the Haidi Powerbox and family energy storage products in the modular energy storage system. In the context of increasingly fierce competition in the new energy storage industry, Haidi New Energy demonstrated its new solutions in the field of energy storage.
                                    </p>
                                    <p>
                                        As the subsidies for on-grid tariffs in Europe continue to decrease, users who have installed rooftop solar modules in large numbers are looking for suitable solutions to deal with them. Haidi's energy storage products have hit this market pain point, and have achieved remarkable results in reducing the cost of electricity and the dependence of end users on the power grid, as well as improving urban power safety, and promoted the healthy development of the industry. Recently, Haidi's energy storage program has won the favor of partners from various European countries and demonstrated its strong product competitiveness.
                                    </p>
                                    <img data-original={XW_01_img_1} alt=""/>
                                    <img data-original={XW_01_img_2} alt=""/>
                                    <img data-original={XW_01_img_3} alt=""/>
                                    <img data-original={XW_01_img_4} alt=""/>
                                    <img data-original={XW_01_img_5} alt=""/>
                                </div>
                                <div className="XW_activity_list">

                                    <p><span>Last one: </span><a href="javascript:;">European Exhibition</a></p>
                                    <p className="XW_activity_list_return">
                                        <Link_a path={"/news"} text={"Return list"}></Link_a>
                                    </p>
                                    <p><span>Next one: </span><a href="javascript:;">Innovation Technology 2019</a></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}